// src/services/AuthService.js

import axios from "axios";

const url = "https://apidata.satudatapariamankota.id/api/";

const urlwalikota = "https://apidata.satudatapariamankota.id/api/Walikota/";
const idkota = "/kota/16";

export default {
  dataBangunanRTI(page) {
    return axios
      .get(urlwalikota + "dataBangunanRTI" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataBangunanRTK(page) {
    return axios
      .get(urlwalikota + "dataBangunanRTK" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataBangunanWirausaha(page) {
    return axios
      .get(urlwalikota + "dataBangunanWirausaha" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataBangunanSaranaumum(page) {
    return axios
      .get(urlwalikota + "dataBangunanSaranaumum" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // RTI
  dataRTIRumahKumuh(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahKumuh" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahTidakKumuh(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahTidakKumuh" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahMilikSendiri(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahMilikSendiri" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahSewa(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahSewa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahBebasSewa(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahBebasSewa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahDipinjami(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahDipinjami" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahDinas(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahDinas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIRumahLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTIRumahLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILahanMiliksendiri(page) {
    return axios
      .get(urlwalikota + "dataRTILahanMiliksendiri" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILahanMilikOrangLain(page) {
    return axios
      .get(
        urlwalikota + "dataRTILahanMilikOrangLain" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTILahanTanahNegara(page) {
    return axios
      .get(urlwalikota + "dataRTILahanTanahNegara" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILahanLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTILahanLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //

  dataRTILantaiMarmer(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiMarmer" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiPapanTinggi(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiPapanTinggi" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiKeramik(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiKeramik" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiSemen(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiSemen" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiParket(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiParket" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiBambu(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiBambu" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiUbin(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiUbin" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiPapanRendah(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiPapanRendah" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTILantaiLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTILantaiLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Dinding
  dataRTIDindingSemen(page) {
    return axios
      .get(urlwalikota + "dataRTIDindingSemen" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIDindingKayu(page) {
    return axios
      .get(urlwalikota + "dataRTIDindingKayu" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIDindingLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTIDindingLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Jendela
  dataRTIJendelaBerfungsi(page) {
    return axios
      .get(urlwalikota + "dataRTIJendelaBerfungsi" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIJendelaTidakBerfungsi(page) {
    return axios
      .get(
        urlwalikota + "dataRTIJendelaTidakBerfungsi" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIJendelaTidakAda(page) {
    return axios
      .get(urlwalikota + "dataRTIJendelaTidakAda" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Atap
  dataRTIAtapGenteng(page) {
    return axios
      .get(urlwalikota + "dataRTIAtapGenteng" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAtapSeng(page) {
    return axios
      .get(urlwalikota + "dataRTIAtapSeng" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAtapKayu(page) {
    return axios
      .get(urlwalikota + "dataRTIAtapKayu" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAtapLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTIAtapLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // penerangan
  dataRTIPeneranganListrikPLN(page) {
    return axios
      .get(
        urlwalikota + "dataRTIPeneranganListrikPLN" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIPeneranganListriknonPLN(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIPeneranganListriknonPLN" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTIPeneranganLampuMinyak(page) {
    return axios
      .get(
        urlwalikota + "dataRTIPeneranganLampuMinyak" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIPeneranganSumberLainnya(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIPeneranganSumberLainnya" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTIPeneranganTidakAda(page) {
    return axios
      .get(urlwalikota + "dataRTIPeneranganTidakAda" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // energi masak
  dataRTIMemasakGas(page) {
    return axios
      .get(urlwalikota + "dataRTIMemasakGas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIMemasakKayuBakar(page) {
    return axios
      .get(urlwalikota + "dataRTIMemasakKayuBakar" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIMemasakMinyakTanah(page) {
    return axios
      .get(urlwalikota + "dataRTIMemasakMinyakTanah" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIMemasakLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTIMemasakLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // pembuangan sampah
  dataRTIPembuanganSampahTidakAda(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIPembuanganSampahTidakAda" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTIPembuanganSampahKebun(page) {
    return axios
      .get(
        urlwalikota + "dataRTIPembuanganSampahKebun" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIPembuanganSampahDibakar(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIPembuanganSampahDibakar" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTIPembuanganSampahTempatSampah(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIPembuanganSampahTempatSampah" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTIPembuanganSampahDiangkut(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIPembuanganSampahDiangkut" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },

  // fasilitas mck
  dataRTIFasilitasMCKSendiri(page) {
    return axios
      .get(
        urlwalikota + "dataRTIFasilitasMCKSendiri" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIFasilitasMCKBerkelompok(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTIFasilitasMCKBerkelompok" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTIFasilitasMCKUmum(page) {
    return axios
      .get(urlwalikota + "dataRTIFasilitasMCKUmum" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIFasilitasMCKTidakada(page) {
    return axios
      .get(
        urlwalikota + "dataRTIFasilitasMCKTidakada" + idkota + "?page=" + page
      )
      .then((response) => response);
  },

  // sumber air mandi
  dataRTISumberAirMandiSungai(page) {
    return axios
      .get(
        urlwalikota + "dataRTISumberAirMandiSungai" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTISumberAirMandiPerpipaan(page) {
    return axios
      .get(
        urlwalikota +
          "dataRTISumberAirMandiPerpipaan" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  dataRTISumberAirMandiTadahAir(page) {
    return axios
      .get(
        urlwalikota + "dataRTISumberAirMandiTadahAir" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTISumberAirMandiMataAir(page) {
    return axios
      .get(
        urlwalikota + "dataRTISumberAirMandiMataAir" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTISumberAirMandiLainnya(page) {
    return axios
      .get(
        urlwalikota + "dataRTISumberAirMandiLainnya" + idkota + "?page=" + page
      )
      .then((response) => response);
  },

  // bab
  dataRTIFasilitasBABTidakAda(page) {
    return axios
      .get(
        urlwalikota + "dataRTIFasilitasBABTidakAda" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIFasilitasBABSendiri(page) {
    return axios
      .get(
        urlwalikota + "dataRTIFasilitasBABSendiri" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIFasilitasBABUmum(page) {
    return axios
      .get(urlwalikota + "dataRTIFasilitasBABUmum" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIFasilitasBABBersama(page) {
    return axios
      .get(
        urlwalikota + "dataRTIFasilitasBABBersama" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  dataRTIFasilitasBABLainnya(page) {
    return axios
      .get(
        urlwalikota + "dataRTIFasilitasBABLainnya" + idkota + "?page=" + page
      )
      .then((response) => response);
  },

  // air minum
  dataRTIAirminumLedeng(page) {
    return axios
      .get(urlwalikota + "dataRTIAirminumLedeng" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAirminumTadahAir(page) {
    return axios
      .get(urlwalikota + "dataRTIAirminumTadahAir" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAirminumMataAir(page) {
    return axios
      .get(urlwalikota + "dataRTIAirminumMataAir" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAirminumSungai(page) {
    return axios
      .get(urlwalikota + "dataRTIAirminumSungai" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIAirminumLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTIAirminumLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // limbah
  dataRTIlimbahTangki(page) {
    return axios
      .get(urlwalikota + "dataRTIlimbahTangki" + idkota + "?page=" + page)
      .then((response) => response);
  },

  dataRTIlimbahLubang(page) {
    return axios
      .get(urlwalikota + "dataRTIlimbahLubang" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIlimbahSawah(page) {
    return axios
      .get(urlwalikota + "dataRTIlimbahSawah" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIlimbahLainnya(page) {
    return axios
      .get(urlwalikota + "dataRTIlimbahLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Sute
  dataRTISutet(page) {
    return axios
      .get(urlwalikota + "dataRTISutet" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTISungai(page) {
    return axios
      .get(urlwalikota + "dataRTISungai" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIBukit(page) {
    return axios
      .get(urlwalikota + "dataRTIBukit" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // mottor
  dataRTIMotor(page) {
    return axios
      .get(urlwalikota + "dataRTIMotor" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIMobil(page) {
    return axios
      .get(urlwalikota + "dataRTIMobil" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // jaringan telpon
  dataRTITeleponYa(page) {
    return axios
      .get(urlwalikota + "dataRTITeleponYa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTITeleponTidak(page) {
    return axios
      .get(urlwalikota + "dataRTITeleponTidak" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // jaringan telpon gsm
  dataRTIGsmYa(page) {
    return axios
      .get(urlwalikota + "dataRTIGsmYa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIGsmTidak(page) {
    return axios
      .get(urlwalikota + "dataRTIGsmTidak" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // jaringan telpon gsm
  dataRTIGsmCepat(page) {
    return axios
      .get(urlwalikota + "dataRTIGsmCepat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIGsmBiasa(page) {
    return axios
      .get(urlwalikota + "dataRTIGsmBiasa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIGsmLambat(page) {
    return axios
      .get(urlwalikota + "dataRTIGsmLambat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTIGsmTidakAda(page) {
    return axios
      .get(urlwalikota + "dataRTIGsmTidakAda" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // rtk
  dataRTKRumahKumuh(page) {
    return axios
      .get(urlwalikota + "dataRTKRumahKumuh" + idkota + "?page=" + page)
      .then((response) => response);
  },
  dataRTKRumahTidakKumuh(page) {
    return axios
      .get(urlwalikota + "dataRTKRumahTidakKumuh" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // sarana umum
  SaranaUmumMasjid(page) {
    return axios
      .get(urlwalikota + "SaranaUmumMasjid" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumMusholla(page) {
    return axios
      .get(urlwalikota + "SaranaUmumMusholla" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumKantorPemerintahan(page) {
    return axios
      .get(
        urlwalikota + "SaranaUmumKantorPemerintahan" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  SaranaUmumTK(page) {
    return axios
      .get(urlwalikota + "SaranaUmumTK" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumPAUD(page) {
    return axios
      .get(urlwalikota + "SaranaUmumPAUD" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumSD(page) {
    return axios
      .get(urlwalikota + "SaranaUmumSD" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumSMP(page) {
    return axios
      .get(urlwalikota + "SaranaUmumSMP" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumSMA(page) {
    return axios
      .get(urlwalikota + "SaranaUmumSMA" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumPerguruanTinggi(page) {
    return axios
      .get(urlwalikota + "SaranaUmumPerguruanTinggi" + idkota + "?page=" + page)
      .then((response) => response);
  },

  SaranaUmumSekolahAgamaSDSederajat(page) {
    return axios
      .get(
        urlwalikota +
          "SaranaUmumSekolahAgamaSDSederajat" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  SaranaUmumSekolahAgamaSMPSederajat(page) {
    return axios
      .get(
        urlwalikota +
          "SaranaUmumSekolahAgamaSMPSederajat" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  SaranaUmumSekolahAgamaSMASederajat(page) {
    return axios
      .get(
        urlwalikota +
          "SaranaUmumSekolahAgamaSMASederajat" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  SaranaUmumPesantren(page) {
    return axios
      .get(urlwalikota + "SaranaUmumPesantren" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumLapanganOlahraga(page) {
    return axios
      .get(
        urlwalikota + "SaranaUmumLapanganOlahraga" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  SaranaUmumPasar(page) {
    return axios
      .get(urlwalikota + "SaranaUmumPasar" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumPosRonda(page) {
    return axios
      .get(urlwalikota + "SaranaUmumPosRonda" + idkota + "?page=" + page)
      .then((response) => response);
  },

  //
  SaranaUmumBangunan(page) {
    return axios
      .get(urlwalikota + "SaranaUmumBangunan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumNonBangunan(page) {
    return axios
      .get(urlwalikota + "SaranaUmumNonBangunan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  SaranaUmumDanaNegara(page) {
    return axios
      .get(urlwalikota + "SaranaUmumDanaNegara" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumDanaDesa(page) {
    return axios
      .get(urlwalikota + "SaranaUmumDanaDesa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumDanaMasyarakat(page) {
    return axios
      .get(urlwalikota + "SaranaUmumDanaMasyarakat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumDanaSumbanganMasyarakat(page) {
    return axios
      .get(
        urlwalikota +
          "SaranaUmumDanaSumbanganMasyarakat" +
          idkota +
          "?page=" +
          page
      )
      .then((response) => response);
  },
  //
  SaranaUmumLahanTanahNegara(page) {
    return axios
      .get(
        urlwalikota + "SaranaUmumLahanTanahNegara" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  SaranaUmumLahanTanahAdat(page) {
    return axios
      .get(urlwalikota + "SaranaUmumLahanTanahAdat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumLahanTanahDesa(page) {
    return axios
      .get(urlwalikota + "SaranaUmumLahanTanahDesa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SaranaUmumLahanKerjasama(page) {
    return axios
      .get(urlwalikota + "SaranaUmumLahanKerjasama" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Wirausaha
  WirausahaBuka(page) {
    return axios
      .get(urlwalikota + "WirausahaBuka" + idkota + "?page=" + page)
      .then((response) => response);
  },
  WirausahaTutupSementara(page) {
    return axios
      .get(urlwalikota + "WirausahaTutupSementara" + idkota + "?page=" + page)
      .then((response) => response);
  },
  WirausahaTutupPermanen(page) {
    return axios
      .get(urlwalikota + "WirausahaTutupPermanen" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  WirausahaModalMilikSendiri(page) {
    return axios
      .get(
        urlwalikota + "WirausahaModalMilikSendiri" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  WirausahaModalMilikKeluarga(page) {
    return axios
      .get(
        urlwalikota + "WirausahaModalMilikKeluarga" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  WirausahaModalPinjamanModal(page) {
    return axios
      .get(
        urlwalikota + "WirausahaModalPinjamanModal" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  WirausahaModalKerjasama(page) {
    return axios
      .get(urlwalikota + "WirausahaModalKerjasama" + idkota + "?page=" + page)
      .then((response) => response);
  },

  //
  WirausahaIzinUsaha(page) {
    return axios
      .get(urlwalikota + "WirausahaIzinUsaha" + idkota + "?page=" + page)
      .then((response) => response);
  },
  WirausahaTidakIzinUsaha(page) {
    return axios
      .get(urlwalikota + "WirausahaTidakIzinUsaha" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  WirausahaPajakKonsumen(page) {
    return axios
      .get(urlwalikota + "WirausahaPajakKonsumen" + idkota + "?page=" + page)
      .then((response) => response);
  },
  WirausahaTidakPajak(page) {
    return axios
      .get(urlwalikota + "WirausahaTidakPajak" + idkota + "?page=" + page)
      .then((response) => response);
  },
  // SPP PBB
  SPPTPBBAll(page) {
    return axios
      .get(urlwalikota + "SPPTPBBAll" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SPPTPBBLunas(page) {
    return axios
      .get(urlwalikota + "SPPTPBBLunas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SPPTPBBHutang(page) {
    return axios
      .get(urlwalikota + "SPPTPBBHutang" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //Kependudukan
  KKAll(page) {
    return axios
      .get(urlwalikota + "KKAll" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKDalamDesa(page) {
    return axios
      .get(urlwalikota + "KKDalamDesa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKSatuKec(page) {
    return axios
      .get(urlwalikota + "KKSatuKec" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKSatuKota(page) {
    return axios
      .get(urlwalikota + "KKSatuKota" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKLuarKota(page) {
    return axios
      .get(urlwalikota + "KKLuarKota" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  DataKKMasuk(page) {
    return axios
      .get(urlwalikota + "DataKKMasuk" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DataKKPemecahan(page) {
    return axios
      .get(urlwalikota + "DataKKPemecahan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DataPendudukMasuk(page) {
    return axios
      .get(urlwalikota + "DataPendudukMasuk" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  KKMampu(page) {
    return axios
      .get(urlwalikota + "KKMampu" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKBiasa(page) {
    return axios
      .get(urlwalikota + "KKBiasa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKMiskin(page) {
    return axios
      .get(urlwalikota + "KKMiskin" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KKSangatMiskin(page) {
    return axios
      .get(urlwalikota + "KKSangatMiskin" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukAll(page) {
    return axios
      .get(urlwalikota + "PendudukAll" + idkota + "?page=" + page)
      .then((response) => response);
  },
  KematianAll(page) {
    return axios
      .get(urlwalikota + "KematianAll" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukPR(page) {
    return axios
      .get(urlwalikota + "PendudukPR" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukLK(page) {
    return axios
      .get(urlwalikota + "PendudukLK" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukMenetap(page) {
    return axios
      .get(urlwalikota + "PendudukMenetap" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukMerantau(page) {
    return axios
      .get(urlwalikota + "PendudukMerantau" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukIslam(page) {
    return axios
      .get(urlwalikota + "PendudukIslam" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukProtestan(page) {
    return axios
      .get(urlwalikota + "PendudukProtestan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukKatolik(page) {
    return axios
      .get(urlwalikota + "PendudukKatolik" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukHindu(page) {
    return axios
      .get(urlwalikota + "PendudukHindu" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukBudha(page) {
    return axios
      .get(urlwalikota + "PendudukBudha" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukKawin(page) {
    return axios
      .get(urlwalikota + "PendudukKawin" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukTidakKawin(page) {
    return axios
      .get(urlwalikota + "PendudukTidakKawin" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukJanda(page) {
    return axios
      .get(urlwalikota + "PendudukJanda" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukDuda(page) {
    return axios
      .get(urlwalikota + "PendudukDuda" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukWNI(page) {
    return axios
      .get(urlwalikota + "PendudukWNI" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukWNA(page) {
    return axios
      .get(urlwalikota + "PendudukWNA" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukPolitik(page) {
    return axios
      .get(urlwalikota + "PendudukPolitik" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukBekerja(page) {
    return axios
      .get(urlwalikota + "PendudukBekerja" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukTidakBekerja(page) {
    return axios
      .get(urlwalikota + "PendudukTidakBekerja" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukCariKerja(page) {
    return axios
      .get(urlwalikota + "PendudukCariKerja" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukSekolah(page) {
    return axios
      .get(urlwalikota + "PendudukSekolah" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukIRT(page) {
    return axios
      .get(urlwalikota + "PendudukIRT" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukPetaniPemilik(page) {
    return axios
      .get(urlwalikota + "PendudukPetaniPemilik" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukPetaniPenyewa(page) {
    return axios
      .get(urlwalikota + "PendudukPetaniPenyewa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukBuruhTani(page) {
    return axios
      .get(urlwalikota + "PendudukBuruhTani" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukNelayanPemilikKapal(page) {
    return axios
      .get(
        urlwalikota + "PendudukNelayanPemilikKapal" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  PendudukNelayanPenyewaKapal(page) {
    return axios
      .get(
        urlwalikota + "PendudukNelayanPenyewaKapal" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  //
  PendudukBuruhNelayan(page) {
    return axios
      .get(urlwalikota + "PendudukBuruhNelayan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukGuru(page) {
    return axios
      .get(urlwalikota + "PendudukGuru" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukPedagang(page) {
    return axios
      .get(urlwalikota + "PendudukPedagang" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukPekerjaIndustri(page) {
    return axios
      .get(urlwalikota + "PendudukPekerjaIndustri" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukTNI(page) {
    return axios
      .get(urlwalikota + "PendudukTNI" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukPNS(page) {
    return axios
      .get(urlwalikota + "PendudukPNS" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukPolisi(page) {
    return axios
      .get(urlwalikota + "PendudukPolisi" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukPerangkatDesa(page) {
    return axios
      .get(urlwalikota + "PendudukPerangkatDesa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukPegawaiHonorerPemda(page) {
    return axios
      .get(
        urlwalikota + "PendudukPegawaiHonorerPemda" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  PendudukTKI(page) {
    return axios
      .get(urlwalikota + "PendudukTKI" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  PendudukTenagaKesehatan(page) {
    return axios
      .get(urlwalikota + "PendudukTenagaKesehatan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukBuruhHarianLepas(page) {
    return axios
      .get(urlwalikota + "PendudukBuruhHarianLepas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukTenagaKerjaPPPK(page) {
    return axios
      .get(urlwalikota + "PendudukTenagaKerjaPPPK" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendudukTenagaKerjaLainnya(page) {
    return axios
      .get(
        urlwalikota + "PendudukTenagaKerjaLainnya" + idkota + "?page=" + page
      )
      .then((response) => response);
  },
  //umur
  UmurEmpat(page) {
    return axios
      .get(urlwalikota + "UmurEmpat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurLima(page) {
    return axios
      .get(urlwalikota + "UmurLima" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurLimaBelas(page) {
    return axios
      .get(urlwalikota + "UmurLimaBelas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurEnamLima(page) {
    return axios
      .get(urlwalikota + "UmurEnamLima" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  UmurLKEmpat(page) {
    return axios
      .get(urlwalikota + "UmurLKEmpat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurLKLima(page) {
    return axios
      .get(urlwalikota + "UmurLKLima" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurLKLimaBelas(page) {
    return axios
      .get(urlwalikota + "UmurLKLimaBelas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurLKEnamLima(page) {
    return axios
      .get(urlwalikota + "UmurLKEnamLima" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  UmurPREmpat(page) {
    return axios
      .get(urlwalikota + "UmurPREmpat" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurPRLima(page) {
    return axios
      .get(urlwalikota + "UmurPRLima" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurPRLimaBelas(page) {
    return axios
      .get(urlwalikota + "UmurPRLimaBelas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  UmurPREnamLima(page) {
    return axios
      .get(urlwalikota + "UmurPREnamLima" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Pendidikan
  PendidikanTidakSekolah(page) {
    return axios
      .get(urlwalikota + "PendidikanTidakSekolah" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanSD(page) {
    return axios
      .get(urlwalikota + "PendidikanSD" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanSMP(page) {
    return axios
      .get(urlwalikota + "PendidikanSMP" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanSMA(page) {
    return axios
      .get(urlwalikota + "PendidikanSMA" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanDiploma13(page) {
    return axios
      .get(urlwalikota + "PendidikanDiploma13" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanS1(page) {
    return axios
      .get(urlwalikota + "PendidikanS1" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanS2(page) {
    return axios
      .get(urlwalikota + "PendidikanS2" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanS3(page) {
    return axios
      .get(urlwalikota + "PendidikanS3" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanPesantren(page) {
    return axios
      .get(urlwalikota + "PendidikanPesantren" + idkota + "?page=" + page)
      .then((response) => response);
  },
  PendidikanSekolahLainnya(page) {
    return axios
      .get(urlwalikota + "PendidikanSekolahLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //BANTUAN
  BantuanSagasaja(page) {
    return axios
      .get(urlwalikota + "BantuanSagasaja" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanDanaDesa(page) {
    return axios
      .get(urlwalikota + "BantuanDanaDesa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanDanaKota(page) {
    return axios
      .get(urlwalikota + "BantuanDanaKota" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanDanaProvinsi(page) {
    return axios
      .get(urlwalikota + "BantuanDanaProvinsi" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanPKH(page) {
    return axios
      .get(urlwalikota + "BantuanPKH" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanBPNT(page) {
    return axios
      .get(urlwalikota + "BantuanBPNT" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanPresiden(page) {
    return axios
      .get(urlwalikota + "BantuanPresiden" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanUMKM(page) {
    return axios
      .get(urlwalikota + "BantuanUMKM" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanPekerja(page) {
    return axios
      .get(urlwalikota + "BantuanPekerja" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanKetahananPangan(page) {
    return axios
      .get(urlwalikota + "BantuanKetahananPangan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  BantuanLainnya(page) {
    return axios
      .get(urlwalikota + "BantuanLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },
  // Kesehatan
  JKNAll(page) {
    return axios
      .get(urlwalikota + "JKNAll" + idkota + "?page=" + page)
      .then((response) => response);
  },
  JKNKIS(page) {
    return axios
      .get(urlwalikota + "JKNKIS" + idkota + "?page=" + page)
      .then((response) => response);
  },
  JKNBPJSMandiri(page) {
    return axios
      .get(urlwalikota + "JKNBPJSMandiri" + idkota + "?page=" + page)
      .then((response) => response);
  },
  JKNBPJSKetenagakerjaan(page) {
    return axios
      .get(urlwalikota + "JKNBPJSKetenagakerjaan" + idkota + "?page=" + page)
      .then((response) => response);
  },
  JKNLainnya(page) {
    return axios
      .get(urlwalikota + "JKNLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },
  JKNTdkAda(page) {
    return axios
      .get(urlwalikota + "JKNTdkAda" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  Stunting(page) {
    return axios
      .get(urlwalikota + "Stunting" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  Disabilitas(page) {
    return axios
      .get(urlwalikota + "Disabilitas" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasTunanetra(page) {
    return axios
      .get(urlwalikota + "DisabilitasTunanetra" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasTunarungu(page) {
    return axios
      .get(urlwalikota + "DisabilitasTunarungu" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasTunawicara(page) {
    return axios
      .get(urlwalikota + "DisabilitasTunawicara" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasTunadaksa(page) {
    return axios
      .get(urlwalikota + "DisabilitasTunadaksa" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  DisabilitasTunagrahita(page) {
    return axios
      .get(urlwalikota + "DisabilitasTunagrahita" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasTunalaras(page) {
    return axios
      .get(urlwalikota + "DisabilitasTunalaras" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasCacatkusta(page) {
    return axios
      .get(urlwalikota + "DisabilitasCacatkusta" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasCacatGanda(page) {
    return axios
      .get(urlwalikota + "DisabilitasCacatGanda" + idkota + "?page=" + page)
      .then((response) => response);
  },
  DisabilitasDipasung(page) {
    return axios
      .get(urlwalikota + "DisabilitasDipasung" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  SakitMuntaber(page) {
    return axios
      .get(urlwalikota + "SakitMuntaber" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitDemamBerdarah(page) {
    return axios
      .get(urlwalikota + "SakitDemamBerdarah" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitCampak(page) {
    return axios
      .get(urlwalikota + "SakitCampak" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitMalaria(page) {
    return axios
      .get(urlwalikota + "SakitMalaria" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  SakitFluBurung(page) {
    return axios
      .get(urlwalikota + "SakitFluBurung" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitCovid19(page) {
    return axios
      .get(urlwalikota + "SakitCovid19" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitHepatitisB(page) {
    return axios
      .get(urlwalikota + "SakitHepatitisB" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitHepatitisE(page) {
    return axios
      .get(urlwalikota + "SakitHepatitisE" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  SakitDifteri(page) {
    return axios
      .get(urlwalikota + "SakitDifteri" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitChikungunya(page) {
    return axios
      .get(urlwalikota + "SakitChikungunya" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitLeptospirosis(page) {
    return axios
      .get(urlwalikota + "SakitLeptospirosis" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitKolera(page) {
    return axios
      .get(urlwalikota + "SakitKolera" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  SakitGiziBuruk(page) {
    return axios
      .get(urlwalikota + "SakitGiziBuruk" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitJantung(page) {
    return axios
      .get(urlwalikota + "SakitJantung" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitTBC(page) {
    return axios
      .get(urlwalikota + "SakitTBC" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitKanker(page) {
    return axios
      .get(urlwalikota + "SakitKanker" + idkota + "?page=" + page)
      .then((response) => response);
  },
  //
  SakitDiabetes(page) {
    return axios
      .get(urlwalikota + "SakitDiabetes" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitLumpuh(page) {
    return axios
      .get(urlwalikota + "SakitLumpuh" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitStroke(page) {
    return axios
      .get(urlwalikota + "SakitStroke" + idkota + "?page=" + page)
      .then((response) => response);
  },
  SakitLainnya(page) {
    return axios
      .get(urlwalikota + "SakitLainnya" + idkota + "?page=" + page)
      .then((response) => response);
  },

  // Umur Per Umur Baru
  UmurPenduduk(age, page) {
    return axios
      .get(
        urlwalikota + "UmurPenduduk" + idkota + "?umur=" + age + "&page=" + page
      )
      .then((response) => response);
  },
  UmurPendudukLK(age, page) {
    return axios
      .get(
        urlwalikota +
          "UmurPendudukLK" +
          idkota +
          "?umur=" +
          age +
          "&page=" +
          page
      )
      .then((response) => response);
  },
  UmurPendudukPR(age, page) {
    return axios
      .get(
        urlwalikota +
          "UmurPendudukPR" +
          idkota +
          "?umur=" +
          age +
          "&page=" +
          page
      )
      .then((response) => response);
  },
};
