<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Bangunan</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-card v-else elevation="0">
        <v-card-text>
          <v-data-table
            multi-sort
            :headers="headers"
            :items="hasildatas"
            :search="search"
          >
            <template v-slot:[`item.link`]="{ item }">
              <h4 class="teal--text font-weight-bold">
                <span
                  @click="HasilClick(item.link)"
                  style="cursor: pointer"
                  v-click-to-copy
                >
                  {{ item.link }}</span
                >
              </h4>
              <!-- {{ showA }} -->
              <h6 v-show="showA === item.link">{{ item.link }}</h6>
            </template>

            <template v-slot:[`item.no_bangunan`]="{ item }">
              <h4 class="black--text font-weight-bold">
                {{ item.no_bangunan }}
              </h4>
            </template>
            <template v-slot:[`item.kategori`]="{ item }">
              <h4
                v-if="item.kategori !== null"
                class="dark--text font-weight-bold"
              >
                <v-chip
                  v-for="(items, index) in item.kategori"
                  :key="index"
                  class="ma-1"
                  color="teal"
                  label
                  text-color="white"
                >
                  <v-icon small start color="white">mdi-label</v-icon>

                  {{ items }}
                </v-chip>
              </h4>
              <h4 v-else class="dark--text font-weight-bold">-</h4>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/ServicePublik.js";

export default {
  directives: {
    "click-to-copy": {
      bind: (el) => {
        el.addEventListener("click", () => {
          const text = el.innerText.trim();
          navigator.clipboard.writeText(text);
        });
      },
    },
  },
  name: "BangunanUser",
  data() {
    return {
      shownotif: false,
      showA: "",
      showB: "",
      hasildatas: [],
      itemssatuan: [
        {
          id: "1",
          satuan: "CTN",
        },
        {
          id: "2",
          satuan: "LSN",
        },
        {
          id: "3",
          satuan: "PCS",
        },
      ],

      // DATA MASTER BARANG
      sku: "",
      item_code: "",
      nama_brg: "",
      satuan: "",
      modal: "",

      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      // VALIDASI

      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      headers: [
        { text: "Nomor Bangunan", value: "no_bangunan", width: "150px" },
        { text: "Kategori", value: "kategori", width: "100px" },
        { text: "Kota", value: "kota", width: "150px" },
        { text: "Kecamatan", value: "kecamatan", width: "180px" },
        { text: "Kelurahan/Desa", value: "kelurahan", width: "180px" },
        { text: "Dusun", value: "dusun", width: "180px" },
        { text: "Link", value: "link", width: "600px" },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        sku: "",
        nama_brg: "",
        kategori: "",
        item_code: "",
        modal: "",
      },
      defaultItem: {
        provinsi: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Master Barang"
        : "Form Edit Data Master Barang";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    value: {
      handler(after) {
        this.editedItem.modal = this.format(after);
      },
      immediate: true,
    },
  },

  created() {
    this.getDataRumahPublik();
  },
  methods: {
    // RUPIAH FORMAT
    format: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInput() {
      this.editedItem.modal = this.format(this.editedItem.modal);
      this.$emit("v-textfield", (this.modal + "").replace(/[0-9]/g, ""));
    },
    getDataRumahPublik: function () {
      AuthService.getDataRumahPublik().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteMasterBrg(credentials);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });
        this.masterBrg();

        this.$router.push("/superadmin/barang-master").catch(() => {});

        this.dialogDelete = this.closeDelete();
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    HasilClick(text) {
      //
      this.$notification.success("Data Berhasil Di COPY : " + text, {
        timer: 5,
      });
      //   this.showA = text;
    },
    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan

          const credentials = {
            sku: this.editedItem.sku,
            nama_brg: this.editedItem.nama_brg,
            kategori: this.editedItem.kategori,
            item_code: this.editedItem.item_code,
            modal: this.editedItem.modal.replace(/[^0-9]/g, ""),
          };
          const response = await AuthService.addMasterBrg(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.masterBrg();

            this.loading = false;
            this.$router.push("/superadmin/barang-master").catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            sku: this.editedItem.sku,
            nama_brg: this.editedItem.nama_brg,
            kategori: this.editedItem.kategori,
            item_code: this.editedItem.item_code,
            modal: this.editedItem.modal.replace(/[^0-9]/g, ""),
          };
          const response = await AuthService.editMasterBrg(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.masterBrg();

            this.loading = false;
            this.$router.push("/superadmin/barang-master").catch(() => {});
            this.dialog = false;
            this.$refs.form.reset();

            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 7px;
}
.row-pointer >>> h4 :hover {
  cursor: pointer;
}
</style>
