// src/services/AuthService.js

import axios from "axios";

const url = "https://apidata.satudatapariamankota.id/api/";
const urldesa = "https://apidata.satudatapariamankota.id/api/kelurahan/";

export default {
  login(credentials) {
    return axios
      .post(url + "login", credentials)
      .then((response) => response.data);
  },
  signUp(credentials) {
    return axios
      .post(url + "sign-up", credentials)
      .then((response) => response.data);
  },
  postCamat(credentials) {
    return axios
      .post(url + "superadmin/tambahProvinsi", credentials)
      .then((response) => response.data);
  },
  getCamat() {
    return axios
      .get(url + "superadmin/dataProvinsiAll")
      .then((response) => response);
  },
  getKota() {
    return axios
      .get(url + "superadmin/dataKotaAll")
      .then((response) => response);
  },
  hapusCamat(credentials) {
    return axios
      .post(url + "superadmin/deleteProvinsi", credentials)
      .then((response) => response.data);
  },
  updateCamat(credentials) {
    return axios
      .post(url + "superadmin/editProvinsi", credentials)
      .then((response) => response.data);
  },
  getSecretContent() {
    return axios.get(url + "secret-route").then((response) => response.data);
  },

  // BARU
  getKotaByid(credentials) {
    return axios
      .post(url + "getKota", credentials)
      .then((response) => response.data);
  },
  simpanCamat(credentials) {
    return axios
      .post(url + "superadmin/tambahKecamatan", credentials)
      .then((response) => response.data);
  },
  dataCamat() {
    return axios
      .get(url + "superadmin/dataKecamatanAll")
      .then((response) => response);
  },
  deleteCamat(credentials) {
    return axios
      .post(url + "superadmin/deleteKecamatan", credentials)
      .then((response) => response.data);
  },
  ubahCamat(credentials) {
    return axios
      .post(url + "superadmin/editKecamatan", credentials)
      .then((response) => response.data);
  },

  dataKota() {
    return axios
      .get(url + "superadmin/dataKotaAll")
      .then((response) => response);
  },
  simpanKota(credentials) {
    return axios
      .post(url + "superadmin/tambahKota", credentials)
      .then((response) => response.data);
  },
  deleteKota(credentials) {
    return axios
      .post(url + "superadmin/deleteKota", credentials)
      .then((response) => response.data);
  },
  ubahKota(credentials) {
    return axios
      .post(url + "superadmin/editKota", credentials)
      .then((response) => response.data);
  },

  getKecByid(credentials) {
    return axios
      .post(url + "getKecamatan", credentials)
      .then((response) => response.data);
  },
  simpanLurah(credentials) {
    return axios
      .post(url + "superadmin/tambahKelurahan", credentials)
      .then((response) => response.data);
  },
  dataLurah() {
    return axios
      .get(url + "superadmin/dataKelurahanAll")
      .then((response) => response);
  },
  deleteLurah(credentials) {
    return axios
      .post(url + "superadmin/deleteKelurahan", credentials)
      .then((response) => response.data);
  },
  ubahLurah(credentials) {
    return axios
      .post(url + "superadmin/editKelurahan", credentials)
      .then((response) => response.data);
  },

  simpanDusun(credentials) {
    return axios
      .post(url + "superadmin/tambahDusun", credentials)
      .then((response) => response.data);
  },
  getLurahByid(credentials) {
    return axios
      .post(url + "getKelurahan", credentials)
      .then((response) => response.data);
  },
  dataDusun() {
    return axios
      .get(url + "superadmin/dataDusunAll")
      .then((response) => response);
  },
  deleteDusun(credentials) {
    return axios
      .post(url + "superadmin/deleteDusun", credentials)
      .then((response) => response.data);
  },
  ubahDusun(credentials) {
    return axios
      .post(url + "superadmin/editDusun", credentials)
      .then((response) => response.data);
  },

  // Pengguna
  simpanPengguna(credentials) {
    return axios
      .post(url + "superadmin/registerUser", credentials)
      .then((response) => response.data);
  },
  dataPengguna() {
    return axios
      .get(url + "superadmin/dataUserAll")
      .then((response) => response);
  },
  deletePengguna(credentials) {
    return axios
      .post(url + "superadmin/deleteUser", credentials)
      .then((response) => response.data);
  },

  // DATA OPTION

  detailUsers() {
    return axios.get(url + "detailUsers").then((response) => response);
  },
  getDusunByid() {
    return axios.get(url + "getDusunById").then((response) => response);
  },

  getStatuKK() {
    return axios.get(url + "getStatusKK").then((response) => response);
  },

  getStatusMiskin() {
    return axios.get(url + "getStatusMiskin").then((response) => response);
  },
  getJenisKK() {
    return axios.get(url + "getJenisKK").then((response) => response);
  },

  getJK() {
    return axios.get(url + "getJK").then((response) => response);
  },
  getStatusTinggal() {
    return axios.get(url + "getStatusTinggal").then((response) => response);
  },
  getAgama() {
    return axios.get(url + "getAgama").then((response) => response);
  },
  getStatusPerkawinan() {
    return axios.get(url + "getStatusPerkawinan").then((response) => response);
  },
  getKewarganegaraan() {
    return axios.get(url + "getKewarganegaraan").then((response) => response);
  },
  getYaTidak() {
    return axios.get(url + "getYaTidak").then((response) => response);
  },
  getStatusJaringan() {
    return axios.get(url + "getStatusJaringan").then((response) => response);
  },

  getKondisiPekerjaan() {
    return axios.get(url + "getKondisiPekerjaan").then((response) => response);
  },
  getDetailPekerjaan() {
    return axios.get(url + "getPekerjaan").then((response) => response);
  },
  getPendidikan() {
    return axios.get(url + "getPendidikan").then((response) => response);
  },
  getJKN() {
    return axios.get(url + "getJKN").then((response) => response);
  },
  getJenisPenyakit() {
    return axios.get(url + "getJenisPenyakit").then((response) => response);
  },
  getDisabelitas() {
    return axios.get(url + "getDisabelitas").then((response) => response);
  },

  // Data penduduk mardian
  getDataWargaAllMardian() {
    return axios
      .get(url + "adminuser/dataWargaAll")
      .then((response) => response);
  },
  // WARGA KK DESA KELURAHAN
  simpanWarga(formData) {
    return axios
      .post(urldesa + "tambahWarga", formData)
      .then((response) => response.data);
  },
  tambahWargaKK(formData) {
    return axios
      .post(urldesa + "tambahWargaKK", formData)
      .then((response) => response.data);
  },

  editWarga(formData) {
    return axios
      .post(urldesa + "editWarga", formData)
      .then((response) => response.data);
  },

  pecahKK(formData) {
    return axios
      .post(urldesa + "pecahKK", formData)
      .then((response) => response.data);
  },

  dataKK() {
    return axios.get(urldesa + "dataWargaAll").then((response) => response);
  },
  editKK(formData) {
    return axios
      .post(urldesa + "editKK", formData)
      .then((response) => response.data);
  },
  deleteKK(credentials) {
    return axios
      .post(urldesa + "deleteKK", credentials)
      .then((response) => response.data);
  },
  dataWargaStatistik() {
    return axios
      .get(urldesa + "dataWargaStatistik")
      .then((response) => response);
  },
  getWargaById(credentials) {
    return axios
      .post(urldesa + "getWargaById", credentials)
      .then((response) => response.data);
  },

  // WARGA PENDUDUK KELURAHAN
  getDataWargaAll() {
    return axios.get(urldesa + "getDataWargaAll").then((response) => response);
  },
  deleteWarga(credentials) {
    return axios
      .post(urldesa + "deleteWarga", credentials)
      .then((response) => response.data);
  },
  meniggalWarga(credentials) {
    return axios
      .post(urldesa + "meniggalWarga", credentials)
      .then((response) => response.data);
  },

  dataStatistikWarga() {
    return axios
      .get(urldesa + "dataStatistikWarga")
      .then((response) => response);
  },

  // DATA BANGUNAN

  getKategoriBangunan() {
    return axios.get(url + "getKategoriBangunan").then((response) => response);
  },
  getJenusSaranaUmum() {
    return axios.get(url + "getJenusSaranaUmum").then((response) => response);
  },
  getJenisBangunan() {
    return axios.get(url + "getJenisBangunan").then((response) => response);
  },
  getStatusBangunan() {
    return axios.get(url + "getStatusBangunan").then((response) => response);
  },
  getStatusLahan() {
    return axios.get(url + "getStatusLahan").then((response) => response);
  },

  getStatusOperational() {
    return axios.get(url + "getStatusOperational").then((response) => response);
  },
  getSumberModal() {
    return axios.get(url + "getSumberModal").then((response) => response);
  },
  getKondisiRumah() {
    return axios.get(url + "getKondisiRumah").then((response) => response);
  },
  getStatusSPTPBB() {
    return axios.get(url + "getStatusSPTPBB").then((response) => response);
  },
  getStatusThnLalu() {
    return axios.get(url + "getStatusThnLalu").then((response) => response);
  },

  // Rumah Tinggal Isi
  getTempatTinggal() {
    return axios.get(url + "getTempatTinggal").then((response) => response);
  },
  getStatusLahanB() {
    return axios.get(url + "getStatusLahanB").then((response) => response);
  },
  getJenisLantai() {
    return axios.get(url + "getJenisLantai").then((response) => response);
  },
  getJenisDinding() {
    return axios.get(url + "getJenisDinding").then((response) => response);
  },
  getStatusJendela() {
    return axios.get(url + "getStatusJendela").then((response) => response);
  },
  getBahanAtap() {
    return axios.get(url + "getBahanAtap").then((response) => response);
  },
  getPeneranganRumah() {
    return axios.get(url + "getPeneranganRumah").then((response) => response);
  },
  getEnergiMasak() {
    return axios.get(url + "getEnergiMasak").then((response) => response);
  },
  getKayuBakar() {
    return axios.get(url + "getKayuBakar").then((response) => response);
  },
  getPembuanganSampah() {
    return axios.get(url + "getPembuanganSampah").then((response) => response);
  },
  getFasilitasMCK() {
    return axios.get(url + "getFasilitasMCK").then((response) => response);
  },
  getSumberAirMandi() {
    return axios.get(url + "getSumberAirMandi").then((response) => response);
  },
  getFasilitasBAB() {
    return axios.get(url + "getFasilitasBAB").then((response) => response);
  },
  getSumberAirMinum() {
    return axios.get(url + "getSumberAirMinum").then((response) => response);
  },
  getLimbahCair() {
    return axios.get(url + "getLimbahCair").then((response) => response);
  },
  getTransportasi() {
    return axios.get(url + "getTransportasi").then((response) => response);
  },

  getStatisktikBangunan() {
    return axios
      .get(urldesa + "getStatisktikBangunan")
      .then((response) => response);
  },
  dataBangunanAll() {
    return axios.get(urldesa + "dataBangunanAll").then((response) => response);
  },
  dataSaranaumum() {
    return axios.get(urldesa + "dataSaranaumum").then((response) => response);
  },
  dataWirausaha() {
    return axios.get(urldesa + "dataWirausaha").then((response) => response);
  },
  dataRTK() {
    return axios.get(urldesa + "dataRTK").then((response) => response);
  },
  dataRTI() {
    return axios.get(urldesa + "dataRTI").then((response) => response);
  },

  detailBangunan(credentials) {
    return axios
      .post(urldesa + "detailBangunan", credentials)
      .then((response) => response.data);
  },
  cekKK(credentials) {
    return axios
      .post(urldesa + "cekKK", credentials)
      .then((response) => response.data);
  },

  tambahBangunan(credentials) {
    return axios
      .post(urldesa + "tambahBangunan", credentials)
      .then((response) => response.data);
  },
  editBangunan(credentials) {
    return axios
      .post(urldesa + "editBangunan", credentials)
      .then((response) => response.data);
  },
  deleteBangunan(credentials) {
    return axios
      .post(urldesa + "deleteBangunan", credentials)
      .then((response) => response.data);
  },
  deleteSelectBangunan(credentials) {
    return axios
      .post(urldesa + "deleteSelectBangunan", credentials)
      .then((response) => response.data);
  },

  getBantuanByKK(credentials) {
    return axios
      .post(urldesa + "getBantuanByKK", credentials)
      .then((response) => response);
  },
  getSagasajaByKK(credentials) {
    return axios
      .post(urldesa + "getSagasajaByKK", credentials)
      .then((response) => response);
  },

  // DATA BANTUAN SOSAIL
  cekNik(credentials) {
    return axios
      .post(urldesa + "cekNik", credentials)
      .then((response) => response);
  },
  cekNikKk(credentials) {
    return axios
      .post(urldesa + "cekNikKk", credentials)
      .then((response) => response.data);
  },
  getKategoriBantuan() {
    return axios.get(url + "getKategoriBantuan").then((response) => response);
  },
  tambahBantuan(credentials) {
    return axios
      .post(urldesa + "tambahBantuan", credentials)
      .then((response) => response.data);
  },
  getBantuanAll() {
    return axios.get(urldesa + "getBantuanAll").then((response) => response);
  },
  deleteBantuan(credentials) {
    return axios
      .post(urldesa + "deleteBantuan", credentials)
      .then((response) => response.data);
  },
  editBantuan(credentials) {
    return axios
      .post(urldesa + "editBantuan", credentials)
      .then((response) => response.data);
  },

  // Akses jalan
  getJenisJalan() {
    return axios.get(url + "getJenisJalan").then((response) => response);
  },
  getStatusJalan() {
    return axios.get(url + "getStatusJalan").then((response) => response);
  },
  getAksesJalanAll() {
    return axios.get(urldesa + "getAksesJalanAll").then((response) => response);
  },
  tambahAksesJalan(credentials) {
    return axios
      .post(urldesa + "tambahAksesJalan", credentials)
      .then((response) => response.data);
  },
  deleteAksesJalan(credentials) {
    return axios
      .post(urldesa + "deleteAksesJalan", credentials)
      .then((response) => response.data);
  },
  editAksesJalan(credentials) {
    return axios
      .post(urldesa + "editAksesJalan", credentials)
      .then((response) => response.data);
  },

  // Data Lahan
  getLahanAll() {
    return axios.get(urldesa + "getLahanAll").then((response) => response);
  },
  tambahLahan(credentials) {
    return axios
      .post(urldesa + "tambahLahan", credentials)
      .then((response) => response.data);
  },
  editLahan(credentials) {
    return axios
      .post(urldesa + "editLahan", credentials)
      .then((response) => response.data);
  },
  deleteLahan(credentials) {
    return axios
      .post(urldesa + "deleteLahan", credentials)
      .then((response) => response.data);
  },

  // Data DTKS
  getDtksAll() {
    return axios.get(urldesa + "getDtksAll").then((response) => response);
  },
  tambahDtks(credentials) {
    return axios
      .post(urldesa + "tambahDtks", credentials)
      .then((response) => response.data);
  },
  editDtks(credentials) {
    return axios
      .post(urldesa + "editDtks", credentials)
      .then((response) => response.data);
  },
  deleteDtks(credentials) {
    return axios
      .post(urldesa + "deleteDtks", credentials)
      .then((response) => response.data);
  },

  // SAGASAJA
  tambahSagasaja(credentials) {
    return axios
      .post(urldesa + "tambahSagasaja", credentials)
      .then((response) => response.data);
  },
  getSagasajaAll() {
    return axios.get(urldesa + "getSagasajaAll").then((response) => response);
  },
  deleteSagasaja(credentials) {
    return axios
      .post(urldesa + "deleteSagasaja", credentials)
      .then((response) => response.data);
  },
  editSagasaja(credentials) {
    return axios
      .post(urldesa + "editSagasaja", credentials)
      .then((response) => response.data);
  },

  // SPPT PBB
  nomorBangunan() {
    return axios.get(urldesa + "nomorBangunan").then((response) => response);
  },
  tambahSpptpbb(credentials) {
    return axios
      .post(urldesa + "tambahSpptpbb", credentials)
      .then((response) => response.data);
  },
  getSpptpbbAll() {
    return axios.get(urldesa + "getSpptpbbAll").then((response) => response);
  },
  deleteSpptpbb(credentials) {
    return axios
      .post(urldesa + "deleteSpptpbb", credentials)
      .then((response) => response.data);
  },
  editSpptpbb(credentials) {
    return axios
      .post(urldesa + "editSpptpbb", credentials)
      .then((response) => response.data);
  },

  // Perikanan

  getIkanBudidayaAll() {
    return axios
      .get(urldesa + "getIkanBudidayaAll")
      .then((response) => response);
  },
  getIkanKategori() {
    return axios.get(url + "getIkanKategori").then((response) => response);
  },

  editIkanBudidaya(credentials) {
    return axios
      .post(urldesa + "editIkanBudidaya", credentials)
      .then((response) => response.data);
  },
  tambahIkanBudidaya(credentials) {
    return axios
      .post(urldesa + "tambahIkanBudidaya", credentials)
      .then((response) => response.data);
  },
  deleteIkanBudidaya(credentials) {
    return axios
      .post(urldesa + "deleteIkanBudidaya", credentials)
      .then((response) => response.data);
  },
  getIkanTangkapAll() {
    return axios
      .get(urldesa + "getIkanTangkapAll")
      .then((response) => response);
  },
  getAlatTangkap() {
    return axios.get(url + "getAlatTangkap").then((response) => response);
  },
  getKapalTangkap() {
    return axios.get(url + "getKapalTangkap").then((response) => response);
  },

  deleteIkanTangkap(credentials) {
    return axios
      .post(urldesa + "deleteIkanTangkap", credentials)
      .then((response) => response.data);
  },
  tambahIkanTangkap(credentials) {
    return axios
      .post(urldesa + "tambahIkanTangkap", credentials)
      .then((response) => response.data);
  },
  editIkanTangkap(credentials) {
    return axios
      .post(urldesa + "editIkanTangkap", credentials)
      .then((response) => response.data);
  },

  // Dasawisma PKK
  getDasawismaAll() {
    return axios.get(urldesa + "getDasawismaAll").then((response) => response);
  },
  tambahDasawisma(credentials) {
    return axios
      .post(urldesa + "tambahDasawisma", credentials)
      .then((response) => response.data);
  },
  getJanisMakanan() {
    return axios.get(url + "getJanisMakanan").then((response) => response);
  },
  getKegiatanKesehatan() {
    return axios.get(url + "getKegiatanKesehatan").then((response) => response);
  },
  getPerencanaanKesehatan() {
    return axios
      .get(url + "getPerencanaanKesehatan")
      .then((response) => response);
  },
  deleteDasawisma(credentials) {
    return axios
      .post(urldesa + "deleteDasawisma", credentials)
      .then((response) => response.data);
  },
  editDasawisma(credentials) {
    return axios
      .post(urldesa + "editDasawisma", credentials)
      .then((response) => response.data);
  },

  // Kelahiran Bayi
  getKelahiranBayiAll() {
    return axios
      .get(urldesa + "getKelahiranBayiAll")
      .then((response) => response);
  },
  tambahKelahiranBayi(credentials) {
    return axios
      .post(urldesa + "tambahKelahiranBayi", credentials)
      .then((response) => response.data);
  },
  editKelahiranBayi(credentials) {
    return axios
      .post(urldesa + "editKelahiranBayi", credentials)
      .then((response) => response.data);
  },
  deleteKelahiranBayi(credentials) {
    return axios
      .post(urldesa + "deleteKelahiranBayi", credentials)
      .then((response) => response.data);
  },

  // Kematian Bayi
  getKematianKelahiranAll() {
    return axios
      .get(urldesa + "getKematianKelahiranAll")
      .then((response) => response);
  },
  tambahKematianKelahiran(credentials) {
    return axios
      .post(urldesa + "tambahKematianKelahiran", credentials)
      .then((response) => response.data);
  },
  editKematianKelahiran(credentials) {
    return axios
      .post(urldesa + "editKematianKelahiran", credentials)
      .then((response) => response.data);
  },
  deleteKematianKelahiran(credentials) {
    return axios
      .post(urldesa + "deleteKematianKelahiran", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Ternak Unggas
  getTernakUnggasPeternakanAll() {
    return axios
      .get(urldesa + "getTernakUnggasPeternakanAll")
      .then((response) => response);
  },
  tambahTernakUnggasPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahTernakUnggasPeternakan", credentials)
      .then((response) => response.data);
  },
  editTernakUnggasPeternakan(credentials) {
    return axios
      .post(urldesa + "editTernakUnggasPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteTernakUnggasPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteTernakUnggasPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Populasi
  getDataPopulasiPeternakanAll() {
    return axios
      .get(urldesa + "getDataPopulasiPeternakanAll")
      .then((response) => response);
  },
  tambahDataPopulasiPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahDataPopulasiPeternakan", credentials)
      .then((response) => response.data);
  },
  editDataPopulasiPeternakan(credentials) {
    return axios
      .post(urldesa + "editDataPopulasiPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteDataPopulasiPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteDataPopulasiPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Kemitraan
  getDataKemitraanPeternakanAll() {
    return axios
      .get(urldesa + "getDataKemitraanPeternakanAll")
      .then((response) => response);
  },
  tambahDataKemitraanPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahDataKemitraanPeternakan", credentials)
      .then((response) => response.data);
  },
  editDataKemitraanPeternakan(credentials) {
    return axios
      .post(urldesa + "editDataKemitraanPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteDataKemitraanPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteDataKemitraanPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Usulan Bantuan
  getDataUsulanBantuanPeternakanAll() {
    return axios
      .get(urldesa + "getDataUsulanBantuanPeternakanAll")
      .then((response) => response);
  },
  tambahDataUsulanBantuanPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahDataUsulanBantuanPeternakan", credentials)
      .then((response) => response.data);
  },
  editDataUsulanBantuanPeternakan(credentials) {
    return axios
      .post(urldesa + "editDataUsulanBantuanPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteDataUsulanBantuanPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteDataUsulanBantuanPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Penyakit Ternak
  getPenyakitTernakPeternakanAll() {
    return axios
      .get(urldesa + "getPenyakitTernakPeternakanAll")
      .then((response) => response);
  },
  tambahPenyakitTernakPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahPenyakitTernakPeternakan", credentials)
      .then((response) => response.data);
  },
  editPenyakitTernakPeternakan(credentials) {
    return axios
      .post(urldesa + "editPenyakitTernakPeternakan", credentials)
      .then((response) => response.data);
  },
  deletePenyakitTernakPeternakan(credentials) {
    return axios
      .post(urldesa + "deletePenyakitTernakPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Data Walet
  getDataWaletPeternakanAll() {
    return axios
      .get(urldesa + "getDataWaletPeternakanAll")
      .then((response) => response);
  },
  tambahDataWaletPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahDataWaletPeternakan", credentials)
      .then((response) => response.data);
  },
  editDataWaletPeternakan(credentials) {
    return axios
      .post(urldesa + "editDataWaletPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteDataWaletPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteDataWaletPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Data Hewan Peliharaan
  getHewanPeliharaanPeternakanAll() {
    return axios
      .get(urldesa + "getHewanPeliharaanPeternakanAll")
      .then((response) => response);
  },
  tambahHewanPeliharaanPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahHewanPeliharaanPeternakan", credentials)
      .then((response) => response.data);
  },
  editHewanPeliharaanPeternakan(credentials) {
    return axios
      .post(urldesa + "editHewanPeliharaanPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteHewanPeliharaanPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteHewanPeliharaanPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Data Kelompok Ternak
  getKelompokTernakPeternakanAll() {
    return axios
      .get(urldesa + "getKelompokTernakPeternakanAll")
      .then((response) => response);
  },
  tambahKelompokTernakPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahKelompokTernakPeternakan", credentials)
      .then((response) => response.data);
  },
  editKelompokTernakPeternakan(credentials) {
    return axios
      .post(urldesa + "editKelompokTernakPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteKelompokTernakPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteKelompokTernakPeternakan", credentials)
      .then((response) => response.data);
  },

  // Peternakan - Data Kelompok Ternak
  getDataUphPeternakanAll() {
    return axios
      .get(urldesa + "getDataUphPeternakanAll")
      .then((response) => response);
  },
  tambahDataUphPeternakan(credentials) {
    return axios
      .post(urldesa + "tambahDataUphPeternakan", credentials)
      .then((response) => response.data);
  },
  editDataUphPeternakan(credentials) {
    return axios
      .post(urldesa + "editDataUphPeternakan", credentials)
      .then((response) => response.data);
  },
  deleteDataUphPeternakan(credentials) {
    return axios
      .post(urldesa + "deleteDataUphPeternakan", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Potensi Petani
  getPotensiPetaniAll() {
    return axios
      .get(urldesa + "getPotensiPetaniAll")
      .then((response) => response);
  },
  tambahPotensiPetani(credentials) {
    return axios
      .post(urldesa + "tambahPotensiPetani", credentials)
      .then((response) => response.data);
  },
  editPotensiPetani(credentials) {
    return axios
      .post(urldesa + "editPotensiPetani", credentials)
      .then((response) => response.data);
  },
  deletePotensiPetani(credentials) {
    return axios
      .post(urldesa + "deletePotensiPetani", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Kelompok Tani
  getKelompokTaniPertanianAll() {
    return axios
      .get(urldesa + "getKelompokTaniPertanianAll")
      .then((response) => response);
  },
  tambahKelompokTaniPertanian(credentials) {
    return axios
      .post(urldesa + "tambahKelompokTaniPertanian", credentials)
      .then((response) => response.data);
  },
  editKelompokTaniPertanian(credentials) {
    return axios
      .post(urldesa + "editKelompokTaniPertanian", credentials)
      .then((response) => response.data);
  },
  deleteKelompokTaniPertanian(credentials) {
    return axios
      .post(urldesa + "deleteKelompokTaniPertanian", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Kepemilikan lahan Sawah
  getPemilikSawahPertanianAll() {
    return axios
      .get(urldesa + "getPemilikSawahPertanianAll")
      .then((response) => response);
  },
  tambahPemilikSawahPertanian(credentials) {
    return axios
      .post(urldesa + "tambahPemilikSawahPertanian", credentials)
      .then((response) => response.data);
  },
  editPemilikSawahPertanian(credentials) {
    return axios
      .post(urldesa + "editPemilikSawahPertanian", credentials)
      .then((response) => response.data);
  },
  deletePemilikSawahPertanian(credentials) {
    return axios
      .post(urldesa + "deletePemilikSawahPertanian", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Bantuan Pertanian
  getBantuanPertanianAll() {
    return axios
      .get(urldesa + "getBantuanPertanianAll")
      .then((response) => response);
  },
  tambahBantuanPertanian(credentials) {
    return axios
      .post(urldesa + "tambahBantuanPertanian", credentials)
      .then((response) => response.data);
  },
  editBantuanPertanian(credentials) {
    return axios
      .post(urldesa + "editBantuanPertanian", credentials)
      .then((response) => response.data);
  },
  deleteBantuanPertanian(credentials) {
    return axios
      .post(urldesa + "deleteBantuanPertanian", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Penyakit Tanaman
  getPenyakitPertanianAll() {
    return axios
      .get(urldesa + "getPenyakitPertanianAll")
      .then((response) => response);
  },
  tambahPenyakitPertanian(credentials) {
    return axios
      .post(urldesa + "tambahPenyakitPertanian", credentials)
      .then((response) => response.data);
  },
  editPenyakitPertanian(credentials) {
    return axios
      .post(urldesa + "editPenyakitPertanian", credentials)
      .then((response) => response.data);
  },
  deletePenyakitPertanian(credentials) {
    return axios
      .post(urldesa + "deletePenyakitPertanian", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Alsintan
  getAlsintanPertanianAll() {
    return axios
      .get(urldesa + "getAlsintanPertanianAll")
      .then((response) => response);
  },
  tambahAlsintanPertanian(credentials) {
    return axios
      .post(urldesa + "tambahAlsintanPertanian", credentials)
      .then((response) => response.data);
  },
  editAlsintanPertanian(credentials) {
    return axios
      .post(urldesa + "editAlsintanPertanian", credentials)
      .then((response) => response.data);
  },
  deleteAlsintanPertanian(credentials) {
    return axios
      .post(urldesa + "deleteAlsintanPertanian", credentials)
      .then((response) => response.data);
  },

  // Pertanian - Data Pasca Panen
  getPascaPanenPertanianAll() {
    return axios
      .get(urldesa + "getPascaPanenPertanianAll")
      .then((response) => response);
  },
  tambahPascaPanenPertanian(credentials) {
    return axios
      .post(urldesa + "tambahPascaPanenPertanian", credentials)
      .then((response) => response.data);
  },
  editPascaPanenPertaninan(credentials) {
    return axios
      .post(urldesa + "editPascaPanenPertanian", credentials)
      .then((response) => response.data);
  },
  deletePascaPanenPertanian(credentials) {
    return axios
      .post(urldesa + "deletePascaPanenPertanian", credentials)
      .then((response) => response.data);
  },

  // Posyandu - Data Balita
  getDataBalitaPosyanduAll() {
    return axios
      .get(urldesa + "getDataBalitaPosyanduAll")
      .then((response) => response);
  },
  tambahDataBalitaPosyandu(credentials) {
    return axios
      .post(urldesa + "tambahDataBalitaPosyandu", credentials)
      .then((response) => response.data);
  },
  editDataBalitaPosyandu(credentials) {
    return axios
      .post(urldesa + "editDataBalitaPosyandu", credentials)
      .then((response) => response.data);
  },
  deleteDataBalitaPosyandu(credentials) {
    return axios
      .post(urldesa + "deleteDataBalitaPosyandu", credentials)
      .then((response) => response.data);
  },

  // Posyandu - Data Ibu Hamil

  cekNiks(credentials) {
    return axios
      .post(urldesa + "cekNikk", credentials)
      .then((response) => response);
  },

  getDataIbuHamilPosyanduAll() {
    return axios
      .get(urldesa + "getDataIbuHamilPosyanduAll")
      .then((response) => response);
  },
  editDataIbuHamilPosyandu(credentials) {
    return axios
      .post(urldesa + "editDataIbuHamilPosyandu", credentials)
      .then((response) => response.data);
  },
  tambahDataIbuHamilPosyandu(credentials) {
    return axios
      .post(urldesa + "tambahDataIbuHamilPosyandu", credentials)
      .then((response) => response.data);
  },
  deleteDataIbuHamilPosyandu(credentials) {
    return axios
      .post(urldesa + "deleteDataIbuHamilPosyandu", credentials)
      .then((response) => response.data);
  },

  // Posyandu - Data KABE
  getDataKBPosyanduAll() {
    return axios
      .get(urldesa + "getDataKBPosyanduAll")
      .then((response) => response);
  },
  tambahDataKBPosyandu(credentials) {
    return axios
      .post(urldesa + "tambahDataKBPosyandu", credentials)
      .then((response) => response.data);
  },
  editDataKBPosyandu(credentials) {
    return axios
      .post(urldesa + "editDataKBPosyandu", credentials)
      .then((response) => response.data);
  },
  deleteDataKBPosyandu(credentials) {
    return axios
      .post(urldesa + "deleteDataKBPosyandu", credentials)
      .then((response) => response.data);
  },

  // Detail Bangunan
  getIkanBudidayaByKK(credentials) {
    return axios
      .post(urldesa + "getIkanBudidayaByKK", credentials)
      .then((response) => response.data);
  },
  getIkanTangkapByKK(credentials) {
    return axios
      .post(urldesa + "getIkanTangkapByKK", credentials)
      .then((response) => response.data);
  },
  getIbuHamilByKK(credentials) {
    return axios
      .post(urldesa + "getIbuHamilByKK", credentials)
      .then((response) => response.data);
  },
  getDataBalitaByKK(credentials) {
    return axios
      .post(urldesa + "getDataBalitaByKK", credentials)
      .then((response) => response.data);
  },
  getDataKBByKK(credentials) {
    return axios
      .post(urldesa + "getDataKBByKK", credentials)
      .then((response) => response.data);
  },
  getDataPKKByKK(credentials) {
    return axios
      .post(urldesa + "getDataPKKByKK", credentials)
      .then((response) => response.data);
  },
  getTernakUnggasByKK(credentials) {
    return axios
      .post(urldesa + "getTernakUnggasByKK", credentials)
      .then((response) => response.data);
  },
  getDataPopulasiByKK(credentials) {
    return axios
      .post(urldesa + "getDataPopulasiByKK", credentials)
      .then((response) => response.data);
  },
  getDataKemitraanByKK(credentials) {
    return axios
      .post(urldesa + "getDataKemitraanByKK", credentials)
      .then((response) => response.data);
  },
  getDataBantuanPeternakanByKK(credentials) {
    return axios
      .post(urldesa + "getDataBantuanPeternakanByKK", credentials)
      .then((response) => response.data);
  },
  getDataPenyakitTernakByKK(credentials) {
    return axios
      .post(urldesa + "getDataPenyakitTernakByKK", credentials)
      .then((response) => response.data);
  },

  getDataWaletByKK(credentials) {
    return axios
      .post(urldesa + "getDataWaletByKK", credentials)
      .then((response) => response.data);
  },
  getDataHewanPeliharaanByKK(credentials) {
    return axios
      .post(urldesa + "getDataHewanPeliharaanByKK", credentials)
      .then((response) => response.data);
  },
  getDataUPHByKK(credentials) {
    return axios
      .post(urldesa + "getDataUPHByKK", credentials)
      .then((response) => response.data);
  },

  getPotensiPertanianByKK(credentials) {
    return axios
      .post(urldesa + "getPotensiPertanianByKK", credentials)
      .then((response) => response.data);
  },
  getPenyakitPertanianByKK(credentials) {
    return axios
      .post(urldesa + "getPenyakitPertanianByKK", credentials)
      .then((response) => response.data);
  },
  getAlsintanPertanianByKK(credentials) {
    return axios
      .post(urldesa + "getAlsintanPertanianByKK", credentials)
      .then((response) => response.data);
  },
  getPascaPanenPertanianByKK(credentials) {
    return axios
      .post(urldesa + "getPascaPanenPertanianByKK", credentials)
      .then((response) => response.data);
  },
  getPemilikSawahByKK(credentials) {
    return axios
      .post(urldesa + "getPemilikSawahByKK", credentials)
      .then((response) => response.data);
  },
};
