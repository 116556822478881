<template>
  <v-container class="py-5 px-6" fluid>
    <v-toolbar dense elevation="0">
      <v-toolbar-title><h5>Halaman Beranda WaliKota</h5></v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <br />
  </v-container>
</template>
<script>
export default {
  name: "HomeKota",
  data: () => ({}),
};
</script>
