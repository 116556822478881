<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Data Statistik - Rumah Tinggal Isi</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="blue"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />
    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <!-- PART SATU -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KONDISI RUMAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatas.data.kondisi_rumah" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.kondisi_rumah"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.kondisi_rumah"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS TEMPAT TINGGAL</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatas.data.status_tempat" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.status_tempat"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.status_tempat"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS LAHAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatas.data.status_lahan" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.status_lahan"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.status_lahan"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JENIS LANTAI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatas.data.jenis_lantai" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.jenis_lantai"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.jenis_lantai"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JENIS DINDING</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatas.data.jenis_dinding" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.jenis_dinding"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.jenis_dinding"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS JENDELA</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatas.data.status_jendela"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.status_jendela"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.status_jendela"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK BAHAN ATAP</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatas.data.bahan_atap" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.bahan_atap"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.bahan_atap"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PENERANGAN RUMAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatas.data.penerangan_rumah"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.penerangan_rumah"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="
                                  itemdatas.data.penerangan_rumah
                                "
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- PART DUA-->

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK ENERGI MASAK</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatasdua.data.energi_masak"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatasdua.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap v-bind:databaru="itemdata[0].energi_masak" />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.energi_masak"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PEMBUANGAN SAMPAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatasdua.data.pembuangan_sampah"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatasdua.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].pembuangan_sampah"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="
                                  itemdatas.data.pembuangan_sampah
                                "
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK FASILITAS MCK</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatasdua.data.fasilitas_mck"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatasdua.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap v-bind:databaru="itemdata[0].fasilitas_mck" />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.fasilitas_mck"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK SUMBER AIR MANDI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatasdua.data.sumber_air_mandi"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatasdua.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].sumber_air_mandi"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="
                                  itemdatas.data.sumber_air_mandi
                                "
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK FASILITAS BAB</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatasdua.data.fasilitas_bab"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatasdua.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap v-bind:databaru="itemdata[0].fasilitas_bab" />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.fasilitas_bab"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- PART TIGA -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK SUMBER AIR MINUM</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.sumber_air_mandi"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.sumber_air_mandi"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="
                                  itemdatas.data.sumber_air_mandi
                                "
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PEMBUANGAN LIMBAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.pembuangan_limbah"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.pembuangan_limbah"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="
                                  itemdatas.data.pembuangan_limbah
                                "
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK SUTET</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap v-bind:databaru="hasildatastiga.data.sutet" />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap v-bind:databaru="itemdata[0].data.sutet" />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.sutet"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK BANTARAN SUNGAI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.bantaran_sungai"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.bantaran_sungai"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.bantaran_sungai"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK LERENG BUKIT</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.lereng_bukit"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.lereng_bukit"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.lereng_bukit"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JARINGAN TELEPON</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.jaringan_telepon"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.jaringan_telepon"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="
                                  itemdatas.data.jaringan_telepon
                                "
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JARINGAN GSM</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.jaringan_gsm"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.jaringan_gsm"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.jaringan_gsm"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS JARINGAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatastiga.data.status_jaringan"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatastiga.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.status_jaringan"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.status_jaringan"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";

import ChartMap from "@/components/ChartMap.vue";

export default {
  name: "PublikLahan",
  components: { ChartMap },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,
      sloadingdua: true,
      firstLoaddua: true,
      sloadingtiga: true,
      firstLoadtiga: true,

      // chart DUA
      chartDatadua: "",
      chartDatatiga: "",
      chartDataempat: "",
      chartDatalima: "",
      chartDataenam: "",
      chartDatatujuh: "",
      chartDatalapan: "",

      chartDataduax: "",
      chartDatatigax: "",
      chartDataempatx: "",
      chartDatalimax: "",

      chartDataduaz: "",
      chartDatatigaz: "",
      chartDataempatz: "",
      chartDatalimaz: "",
      chartDataenamz: "",
      chartDatatujuhz: "",
      chartDatalapanz: "",

      // kelurahan p1
      chartDataduak: [],
      chartDataduak2: [],
      chartDataduak3: [],
      chartDataduak4: [],
      chartDataduak5: [],
      chartDataduak6: [],
      chartDataduak7: [],
      chartDataduak8: [],

      // kelurahan part 2
      chartDataduajk: [],
      chartDataduajk2: [],
      chartDataduajk3: [],
      chartDataduajk4: [],
      chartDataduajk5: [],

      // kelurahan part 3
      chartDataduakz: [],
      chartDataduakz2: [],
      chartDataduakz3: [],
      chartDataduakz4: [],
      chartDataduakz5: [],
      chartDataduakz6: [],
      chartDataduakz7: [],
      chartDataduakz8: [],

      // Chart
      hasildatas: [{}],
      hasildatasdua: [{}],
      hasildatastiga: [{}],
      series: [44, 55, 41, 17, 15],
      chartData: "",
      chartDatax: "",
      chartDataz: "",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataRumahTinggalIsi();
    this.stsDataRumahTinggalIsiI();
    this.stsDataRumahTinggalIsiII();
  },
  methods: {
    stsDataRumahTinggalIsi: function () {
      ServicePublik.stsDataRumahTinggalIsi().then(
        function (response) {
          this.hasildatas = response.data.data[0];

          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    stsDataRumahTinggalIsiI: function () {
      ServicePublik.stsDataRumahTinggalIsiI().then(
        function (response) {
          this.hasildatasdua = response.data.data[0];

          this.firstLoaddua = false;
          this.sloadingdua = false;
        }.bind(this)
      );
    },
    stsDataRumahTinggalIsiII: function () {
      ServicePublik.stsDataRumahTinggalIsiII().then(
        function (response) {
          this.hasildatastiga = response.data.data[0];

          this.firstLoadtiga = false;
          this.sloadingtiga = false;
        }.bind(this)
      );
    },
  },
  mounted() {
    // // ronde satu
    // this.renderChart(this.chartData);
    // this.renderChart(this.chartDatadua);
    // this.renderChart(this.chartDatatiga);
    // this.renderChart(this.chartDataempat);
    // this.renderChart(this.chartDatalima);
    // this.renderChart(this.chartDataenam);
    // this.renderChart(this.chartDatatujuh);
    // this.renderChart(this.chartDatalapan);
    // //ronde duo
    // this.renderChart(this.chartDatax);
    // this.renderChart(this.chartDataduax);
    // this.renderChart(this.chartDatatigax);
    // this.renderChart(this.chartDataempatx);
    // this.renderChart(this.chartDatalimax);
    // // ronde tiga
    // this.renderChart(this.chartDataz);
    // this.renderChart(this.chartDataduaz);
    // this.renderChart(this.chartDatatigaz);
    // this.renderChart(this.chartDataempatz);
    // this.renderChart(this.chartDatalimaz);
    // this.renderChart(this.chartDataenamz);
    // this.renderChart(this.chartDatatujuhz);
    // this.renderChart(this.chartDatalapanz);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
