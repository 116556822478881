import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import PublicLayout from "../layouts/LoginLay.vue";
import DepanLayout from "../layouts/DepanLay.vue";
import MardianLay from "../layouts/MardianLay.vue";
import Login from "../views/Auth/LoginView.vue";

import DashDesaLay from "../layouts/DashDesaLay.vue";
import AppLayout from "../layouts/DashboardLay.vue";
import Notfound from "../views/Error/404.vue";
import Beranda from "../views/HomeView.vue";

import Provinsi from "../views/ProvinsiView.vue";
import Kota from "../views/KotaView.vue";
import Kecamatan from "../views/KecamatanView.vue";
import Kelurahan from "../views/Kelurahan.vue";
import Dusun from "../views/DusunView.vue";
import Pengguna from "../views/PenggunaView.vue";

//Base Rute Provinsi
import HomeProvinsi from "../views/provinsi/HomeProvinsi.vue";

//Base Rute Kota
import HomeKota from "../views/kota/HomeKota.vue";
import RtiKota from "../views/kota/RtiKota.vue";

//Base Rute Kecamatan
import HomeKecamatan from "../views/kecamatan/HomeKecamatan.vue";

//Base Rute Kelurahan
import HomeKelurahan from "../views/kelurahan/HomeKelurahan.vue";

// Rute Penduduk
import PendudukFormKel from "../views/kelurahan/PendudukFormKel.vue";
import PendudukKK from "../views/kelurahan/PendudukKK.vue";
import PendudukWarga from "../views/kelurahan/PendudukWarga.vue";

// Rute Bangunan
import BangunanForm from "../views/kelurahan/BangunanForm.vue";
import BangunanData from "../views/kelurahan/BangunanData.vue";
import SaranaUmumData from "../views/kelurahan/SaranaData.vue";
import WirausahaData from "../views/kelurahan/WirausahaData.vue";
import RumahKosongData from "../views/kelurahan/RumahKosongData.vue";
import RumahIsiData from "../views/kelurahan/RumahIsiData.vue";
import BangunanEdit from "../views/kelurahan/BangunanEdit.vue";
import BangunanDetail from "../views/kelurahan/BangunanDetail.vue";

// Rute Bantuan Sosial
import BansosData from "../views/kelurahan/bansos/DataBansos.vue";
import BansosForm from "../views/kelurahan/bansos/BansosForm.vue";

// Rute Akses Jalan
import JalanData from "../views/kelurahan/jalan/JalanData.vue";
import JalanForm from "../views/kelurahan/jalan/JalanForm.vue";

// Rute Data Lahan
import LahanData from "../views/kelurahan/lahan/LahanData.vue";
import LahanForm from "../views/kelurahan/lahan/LahanForm.vue";

// Rute Data DTKS
import DtksData from "../views/kelurahan/dtks/DtksData.vue";
import DtksForm from "../views/kelurahan/dtks/DtksForm.vue";

// Rute Data Sagasaja
import SagaData from "../views/kelurahan/sagasaja/SagaData.vue";
import SagaForm from "../views/kelurahan/sagasaja/SagasajaForm.vue";

// Rute Data Spt-PBB
import SpptData from "../views/kelurahan/pbb/SpptData.vue";
import SpptForm from "../views/kelurahan/pbb/SpptForm.vue";

// Rute Data Perikanan
import IkanBudidaya from "../views/kelurahan/perikanan/IkanBudidaya.vue";
import IkanTangkap from "../views/kelurahan/perikanan/IkanTangkap.vue";

// Rute Data Dasawisma
import DataPkk from "../views/kelurahan/dasawisma/DataPkk.vue";
import KelahiranBayi from "../views/kelurahan/dasawisma/KelahiranBayi.vue";
import KematianBayi from "../views/kelurahan/dasawisma/KematianBayi.vue";

// Rute Peternakan
import TernakUnggas from "../views/kelurahan/peternakan/TernakUnggas.vue";
import DataPopulasi from "../views/kelurahan/peternakan/DataPopulasi.vue";
import DataMitra from "../views/kelurahan/peternakan/DataMitra.vue";
import UsulanBantuan from "../views/kelurahan/peternakan/UsulanBantuan.vue";
import TernakSakit from "../views/kelurahan/peternakan/TernakSakit.vue";
import DataWalet from "../views/kelurahan/peternakan/DataWalet.vue";
import HewanPeliharaan from "../views/kelurahan/peternakan/HewanPeliharaan.vue";
import KelompokTernak from "../views/kelurahan/peternakan/KelompokTernak.vue";
import DataUph from "../views/kelurahan/peternakan/DataUph.vue";

// Rute Pertanian
import PotensiTani from "../views/kelurahan/pertanian/PotensiTani.vue";
import KelompokTani from "../views/kelurahan/pertanian/KelompokTani.vue";
import LahanSawah from "../views/kelurahan/pertanian/LahanSawah.vue";
import BantuanTani from "../views/kelurahan/pertanian/BantuanTani.vue";
import SakitTanam from "../views/kelurahan/pertanian/SakitTanam.vue";
import DataAlsintan from "../views/kelurahan/pertanian/DataAlsintan.vue";
import PascaPanen from "../views/kelurahan/pertanian/PascaPanen.vue";

// Rute Posyandu
import DataBalita from "../views/kelurahan/posyandu/DataBalita.vue";
import IbuHamil from "../views/kelurahan/posyandu/IbuHamil.vue";
import DataKb from "../views/kelurahan/posyandu/DataKb.vue";

// HOME PUBLIC RUMAH DATA
import HomePublic from "../views/public/HomePublik.vue";
import RumahData from "../views/public/RumahData.vue";
import PublikLahan from "../views/public/PublikLahan.vue";
import PublikJalan from "../views/public/PublikJalan.vue";
import PublikBangunan from "../views/public/PublikBangunan.vue";
import PublikRumahIsi from "../views/public/PublikRumahIsi.vue";
import PublikRumahKosong from "../views/public/PublikRumahKosong.vue";
import PublikWirausaha from "../views/public/PublikWirausaha.vue";
import PublikSaranaUmum from "../views/public/PublikSaranaUmum.vue";
import PublikSPPT from "../views/public/PublikSPPT.vue";
import PublikPenduduk from "../views/public/PublikPenduduk.vue";
import PublikUmur from "../views/public/PublikUmur.vue";
import PublikKesehatan from "../views/public/PublikKesehatan.vue";
import PublikPendidikan from "../views/public/PublikPendidikan.vue";
import PublikSagaSaja from "../views/public/PublikSagaSaja.vue";
import PublikBansos from "../views/public/PublikBansos.vue";
import PublikDasawisma from "../views/public/PublikDasawisma.vue";
import PublikPosyandu from "../views/public/PublikPosyandu.vue";
import PublikPertanian from "../views/public/PublikPertanian.vue";
import PublikTernak from "../views/public/PublikTernak.vue";
import PublikPerikanan from "../views/public/PublikPerikanan.vue";

// HOME PUBLIK STATISTIK DATA
import StatistikData from "../views/public/statistik/StatistikData.vue";
import StsLahan from "../views/public/statistik/StsLahan.vue";
import StsJalan from "../views/public/statistik/StsJalan.vue";
import StsBangunan from "../views/public/statistik/StsBangunan.vue";
import StsRumahIsi from "../views/public/statistik/StsRumahIsi.vue";
import StsRumahKosong from "../views/public/statistik/StsRumahKosong.vue";
import StsWirausaha from "../views/public/statistik/StsWirausaha.vue";
import StsSarana from "../views/public/statistik/StsSarana.vue";
import StsPBB from "../views/public/statistik/StsPBB.vue";
import StsPenduduk from "../views/public/statistik/StsPenduduk.vue";
import StsUmur from "../views/public/statistik/StsUmur.vue";
import StsKesehatan from "../views/public/statistik/StsKesehatan.vue";
import StsPendidikan from "../views/public/statistik/StsPendidikan.vue";
import StsSaga from "../views/public/statistik/StsSaga.vue";
import StsBansos from "../views/public/statistik/StsBansos.vue";
import StsDasa from "../views/public/statistik/StsDasa.vue";
import StsPosyandu from "../views/public/statistik/StsPosyandu.vue";
import StsPertanian from "../views/public/statistik/StsPertanian.vue";
import StsTernak from "../views/public/statistik/StsTernak.vue";
import StsIkan from "../views/public/statistik/StsIkan.vue";

// test
import AnamPuluh from "../views/AnamPuluh.vue";

// Mardian
import HomeUser from "../views/mardian/HomeUser.vue";
import BangunanUser from "../views/mardian/BangunanUser.vue";
import PendudukUser from "../views/mardian/PendudukUser.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: DepanLayout,
    children: [
      {
        path: "/",
        name: "Beranda",
        component: HomePublic,
      },
      {
        path: "/rumah-data",
        name: "Rumah Data",
        component: RumahData,
      },
      {
        path: "/statistik-data",
        name: "Data Statistik",
        component: StatistikData,
      },
      {
        path: "/rumah-data/lahan",
        name: "Rumah Data | Data Lahan",
        component: PublikLahan,
      },
      {
        path: "/rumah-data/akses-jalan",
        name: "Rumah Data | Data Akses Jalan",
        component: PublikJalan,
      },
      {
        path: "/rumah-data/bangunan",
        name: "Rumah Data | Data Bangunan",
        component: PublikBangunan,
      },
      {
        path: "/rumah-data/bangunan/rumah-isi",
        name: "Rumah Data | Data Bangunan | Rumah Isi",
        component: PublikRumahIsi,
      },
      {
        path: "/rumah-data/bangunan/rumah-kosong",
        name: "Rumah Data | Data Bangunan | Rumah Kosong",
        component: PublikRumahKosong,
      },
      {
        path: "/rumah-data/bangunan/wirausaha",
        name: "Rumah Data | Data Bangunan | Wirausaha",
        component: PublikWirausaha,
      },
      {
        path: "/rumah-data/bangunan/sarana-umum",
        name: "Rumah Data | Data Bangunan | Sarana Umum",
        component: PublikSaranaUmum,
      },
      {
        path: "/rumah-data/sppt-pbb",
        name: "Rumah Data | Data SPPT PBB",
        component: PublikSPPT,
      },
      {
        path: "/rumah-data/penduduk",
        name: "Rumah Data | Data Penduduk",
        component: PublikPenduduk,
      },
      {
        path: "/rumah-data/umur",
        name: "Rumah Data | Data Umur",
        component: PublikUmur,
      },
      {
        path: "/rumah-data/kesehatan",
        name: "Rumah Data | Data Kesehatan",
        component: PublikKesehatan,
      },
      {
        path: "/rumah-data/pendidikan",
        name: "Rumah Data | Data Pendidikan",
        component: PublikPendidikan,
      },
      {
        path: "/rumah-data/sagasaja",
        name: "Rumah Data | Data Sagasaja",
        component: PublikSagaSaja,
      },
      {
        path: "/rumah-data/bansos",
        name: "Rumah Data | Data Bantuan Sosial",
        component: PublikBansos,
      },
      {
        path: "/rumah-data/dasawisma",
        name: "Rumah Data | Data Dasawisma PKK",
        component: PublikDasawisma,
      },
      {
        path: "/rumah-data/posyandu",
        name: "Rumah Data | Data Posyandu",
        component: PublikPosyandu,
      },
      {
        path: "/rumah-data/pertanian",
        name: "Rumah Data | Data Pertanian",
        component: PublikPertanian,
      },
      {
        path: "/rumah-data/peternakan",
        name: "Rumah Data | Data Peternakan",
        component: PublikTernak,
      },
      {
        path: "/rumah-data/perikanan",
        name: "Rumah Data | Data Perikanan",
        component: PublikPerikanan,
      },

      // Statistik Data
      {
        path: "/statistik-data/lahan",
        name: "Statistik | Data Lahan",
        component: StsLahan,
      },
      {
        path: "/statistik-data/akses-jalan",
        name: "Statistik | Data Akses Jalan",
        component: StsJalan,
      },
      {
        path: "/statistik-data/bangunan",
        name: "Statistik | Data Bangunan",
        component: StsBangunan,
      },
      {
        path: "/statistik-data/bangunan/rumah-isi",
        name: "Statistik | Data Rumah Tinggal Isi",
        component: StsRumahIsi,
      },
      {
        path: "/statistik-data/bangunan/rumah-kosong",
        name: "Statistik | Data Rumah Tinggal Kosong",
        component: StsRumahKosong,
      },
      {
        path: "/statistik-data/bangunan/wirausaha",
        name: "Statistik | Data Wirausaha",
        component: StsWirausaha,
      },
      {
        path: "/statistik-data/bangunan/sarana-umum",
        name: "Statistik | Data Sarana Umum",
        component: StsSarana,
      },
      {
        path: "/statistik-data/sppt-pbb",
        name: "Statistik | Data SPPT PBB",
        component: StsPBB,
      },
      {
        path: "/statistik-data/penduduk",
        name: "Statistik | Data Penduduk",
        component: StsPenduduk,
      },
      {
        path: "/statistik-data/umur",
        name: "Statistik | Data Umur",
        component: StsUmur,
      },
      {
        path: "/statistik-data/kesehatan",
        name: "Statistik | Data Kesehatan",
        component: StsKesehatan,
      },
      {
        path: "/statistik-data/pendidikan",
        name: "Statistik | Data Pendidikan",
        component: StsPendidikan,
      },
      {
        path: "/statistik-data/sagasaja",
        name: "Statistik | Data Sagasaja",
        component: StsSaga,
      },
      {
        path: "/statistik-data/bansos",
        name: "Statistik | Data Bantuan Sosial",
        component: StsBansos,
      },
      {
        path: "/statistik-data/dasawisma",
        name: "Statistik | Data Dsawisma",
        component: StsDasa,
      },
      {
        path: "/statistik-data/posyandu",
        name: "Statistik | Data Posyandu",
        component: StsPosyandu,
      },
      {
        path: "/statistik-data/pertanian",
        name: "Statistik | Data Pertanian",
        component: StsPertanian,
      },
      {
        path: "/statistik-data/peternakan",
        name: "Statistik | Data Peternakan",
        component: StsTernak,
      },
      {
        path: "/statistik-data/perikanan",
        name: "Statistik | Data Perikanan",
        component: StsIkan,
      },
    ],
  },
  // RUTE Detail Bangunan Publik
  {
    path: "/opendata/kelurahan/:idkel/nomor/:nobang/bangunan/:idbang",
    name: "Data Detail Bangunan",
    component: AnamPuluh,
  },
  // Rute Auth
  {
    path: "/login",
    name: "Home",
    component: PublicLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
    ],
  },

  // RUTE KELURAHAN
  {
    path: "/admin-kelurahan",
    name: "Admin Kelurahan / Desa",
    component: DashDesaLay,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeKelurahan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Form Penduduk",
        path: "penduduk/create",
        component: PendudukFormKel,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kartu Keluarga",
        path: "penduduk/kk",
        component: PendudukKK,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Penduduk",
        path: "pendudukwarga",
        component: PendudukWarga,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // BANGUNAN
      {
        name: "Form Bangunan",
        path: "bangunan/create",
        component: BangunanForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bangunan",
        path: "bangunan/data",
        component: BangunanData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Sarana Umum",
        path: "bangunan/data/sarana-umum",
        component: SaranaUmumData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Wirausaha",
        path: "bangunan/data/wirausaha",
        component: WirausahaData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Rumah Tinggal Kosong",
        path: "bangunan/data/rumah-tinggal-kosong",
        component: RumahKosongData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Rumah Tinggal Isi",
        path: "bangunan/data/rumah-tinggal-isi",
        component: RumahIsiData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bangunan",
        path: "bangunan/edit/:id",
        component: BangunanEdit,
        props: true,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Detail Data Bangunan",
        path: "bangunan/detail/:id",
        component: BangunanDetail,
        props: true,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // BANTUAN SOSIAL
      {
        name: "Form Bantuan Sosial",
        path: "bansos/create",
        component: BansosForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bantuan Sosial",
        path: "bansos/data",
        component: BansosData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      // AKSES JALAN
      {
        name: "Form Akses Jalan",
        path: "akses-jalan/create",
        component: JalanForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Akses Jalan",
        path: "akses-jalan/data",
        component: JalanData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data LAHAN
      {
        name: "Form Data Lahan",
        path: "lahan/create",
        component: LahanForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Lahan",
        path: "lahan/data",
        component: LahanData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data DTKS
      {
        name: "Form Data DTKS",
        path: "dtks/create",
        component: DtksForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data DTKS",
        path: "dtks/data",
        component: DtksData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data SAGASAJA
      {
        name: "Form Data Sagasaja",
        path: "sagasaja/create",
        component: SagaForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Sagasaja",
        path: "sagasaja/data",
        component: SagaData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data SPPT-PBB
      {
        name: "Form Data SPPT-PBB",
        path: "sppt-pbb/create",
        component: SpptForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data SPPT-PBB",
        path: "sppt-pbb/data",
        component: SpptData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data Perikanan
      {
        name: "Data Ikan Budidaya",
        path: "perikanan/ikan-budidaya",
        component: IkanBudidaya,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Ikan Tangkap",
        path: "perikanan/ikan-tangkap",
        component: IkanTangkap,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data Dasawisma
      {
        name: "Data PKK",
        path: "dasawisma/pkk",
        component: DataPkk,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kelahiran Bayi",
        path: "dasawisma/kelahiran-bayi",
        component: KelahiranBayi,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kematian Bayi",
        path: "dasawisma/kematian-bayi",
        component: KematianBayi,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data Peternakan
      {
        name: "Data Ternak Unggas",
        path: "peternakan/ternak-unggas",
        component: TernakUnggas,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Populasi",
        path: "peternakan/populasi",
        component: DataPopulasi,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kemitraan",
        path: "peternakan/kemitraan",
        component: DataMitra,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Usulan Bantuan",
        path: "peternakan/usulan-bantuan",
        component: UsulanBantuan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Penyakit Ternak",
        path: "peternakan/penyakit-ternak",
        component: TernakSakit,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Walet",
        path: "peternakan/walet",
        component: DataWalet,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Hewan Peliharaan",
        path: "peternakan/hewan-peliharaan",
        component: HewanPeliharaan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kelompok  Ternak",
        path: "peternakan/kelompok-ternak",
        component: KelompokTernak,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data UPH ",
        path: "peternakan/uph",
        component: DataUph,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      // Rute Pertanian

      {
        name: "Data Potensi Petani ",
        path: "pertanian/potensi-petani",
        component: PotensiTani,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kelompok Tani ",
        path: "pertanian/kelompok-tani",
        component: KelompokTani,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kepemilikan Lahan Sawah ",
        path: "pertanian/lahan-sawah",
        component: LahanSawah,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bantuan Pertanian ",
        path: "pertanian/bantuan-pertanian",
        component: BantuanTani,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Penyakit Tanaman ",
        path: "pertanian/penyakit-tanaman",
        component: SakitTanam,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Alsintan ",
        path: "pertanian/alsintan",
        component: DataAlsintan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Pasca Panen ",
        path: "pertanian/pasca-panen",
        component: PascaPanen,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Rute Posyandu
      {
        name: "Data Balita ",
        path: "posyandu/balita",
        component: DataBalita,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Ibu Hamil ",
        path: "posyandu/ibu-hamil",
        component: IbuHamil,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Keluarga Berencana ",
        path: "posyandu/kb",
        component: DataKb,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // 404 NOT FOUND
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  // RUTE SUPERADMIN
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AppLayout,

    children: [
      {
        name: "Beranda ",
        path: "beranda",
        component: Beranda,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Provinsi",
        path: "Provinsi",
        component: Provinsi,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Kota",
        path: "Kota",
        component: Kota,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Kecamatan",
        path: "Kecamatan",
        component: Kecamatan,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Kelurahan",
        path: "Kelurahan",
        component: Kelurahan,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Dusun",
        path: "Dusun",
        component: Dusun,
        meta: {
          superadmin: true,
          login: true,
        },
      },

      {
        name: "Pengguna ",
        path: "pengguna",
        component: Pengguna,
        meta: {
          superadmin: true,
          provinsi: true,
          login: true,
        },
      },

      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  // RUTE KOTA
  {
    path: "/admin-kota",
    name: "Admin Kota",
    component: AppLayout,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeKota,
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Rumah Tinggal Isi",
        path: "data-rti",
        component: RtiKota,
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Rumah Tinggal Kosong",
        path: "data-rtk",
        component: () => import("@/views/kota/RtkKota.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha",
        path: "data-wirausaha",
        component: () => import("@/views/kota/wirausahaKota.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha",
        path: "data-sarana",
        component: () => import("@/views/kota/saranaKota.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // Rumah Tinggal ISI
      {
        name: "RTI - Rumah Kumuh",
        path: "data-rumahkumuh",
        component: () => import("@/views/kota/rumahkumuh.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Tidak Kumuh",
        path: "data-rumahtidakkumuh",
        component: () => import("@/views/kota/rumahtidakkumuh.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Milik Sendiri",
        path: "data-rumahmiliksendiri",
        component: () => import("@/views/kota/rumahmiliksendiri.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Sewa",
        path: "data-rumahsewa",
        component: () => import("@/views/kota/rumahsewa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Bebas Sewa",
        path: "data-bebassewa",
        component: () => import("@/views/kota/bebassewa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Dipinjami",
        path: "data-rumahpinjam",
        component: () => import("@/views/kota/rumahpinjam.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Dinas",
        path: "data-rumahdinas",
        component: () => import("@/views/kota/rumahdinas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Lainnya",
        path: "data-rumahlain",
        component: () => import("@/views/kota/rumahlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lahan Milik Sendiri",
        path: "data-lahanmilik",
        component: () => import("@/views/kota/lahanmilik.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lahan Milik Orang Lain",
        path: "data-lahanorang",
        component: () => import("@/views/kota/lahanorang.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lahan Tanah Negara",
        path: "data-lahannegara",
        component: () => import("@/views/kota/lahannegara.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lahan Lainnya",
        path: "data-lahanlain",
        component: () => import("@/views/kota/lahanlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      //
      {
        name: "RTI - Lantai Marmer",
        path: "data-lantaimarmer",
        component: () => import("@/views/kota/lantaimarmer.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Papan Tinggi",
        path: "data-lantaipapantinggi",
        component: () => import("@/views/kota/lantaipapantinggi.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Keramik",
        path: "data-lantaikeramik",
        component: () => import("@/views/kota/lantaikeramik.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Semen",
        path: "data-lantaisemen",
        component: () => import("@/views/kota/lantaisemen.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Parket",
        path: "data-lantaiparket",
        component: () => import("@/views/kota/lantaiparket.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Bambu",
        path: "data-lantaibambu",
        component: () => import("@/views/kota/lantaibambu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Ubin",
        path: "data-lantaiubin",
        component: () => import("@/views/kota/lantaiubin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Papan Rendah",
        path: "data-lantaipapanrendah",
        component: () => import("@/views/kota/lantaipapanrendah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Lantai Lainnya",
        path: "data-lantailain",
        component: () => import("@/views/kota/lantailain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // dinding
      {
        name: "RTI - Dinding Semen",
        path: "data-dindingsemen",
        component: () => import("@/views/kota/dindingsemen.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Dinding Kayu",
        path: "data-dindingkayu",
        component: () => import("@/views/kota/dindingkayu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Dinding Lainnya",
        path: "data-dindinglain",
        component: () => import("@/views/kota/dindinglain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // Jendela
      {
        name: "RTI - Jendela Berfungsi",
        path: "data-jendelafungsi",
        component: () => import("@/views/kota/jendelafungsi.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Jendela Tidak Berfungsi",
        path: "data-jendelatidakfungsi",
        component: () => import("@/views/kota/jendelatidakfungsi.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Jendela Tidak Ada",
        path: "data-jendelatidakada",
        component: () => import("@/views/kota/jendelatidakada.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // atap
      {
        name: "RTI - Atap Genteng",
        path: "data-atapgenteng",
        component: () => import("@/views/kota/atapgenteng.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Atap Seng",
        path: "data-atapseng",
        component: () => import("@/views/kota/atapseng.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Atap Kayu",
        path: "data-atapkayu",
        component: () => import("@/views/kota/atapkayu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Atap Lainnya",
        path: "data-ataplain",
        component: () => import("@/views/kota/ataplain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // penerangan
      {
        name: "RTI - Penerangan PLN",
        path: "data-terangpln",
        component: () => import("@/views/kota/terangpln.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Penerangan Non PLN",
        path: "data-terangnonpln",
        component: () => import("@/views/kota/terangnonpln.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Penerangan Lampu Minyak",
        path: "data-terangminyak",
        component: () => import("@/views/kota/terangminyak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Penerangan Sumber Lainnya",
        path: "data-terangsumber",
        component: () => import("@/views/kota/terangsumber.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Penerangan Tidak Ada",
        path: "data-terangtidakada",
        component: () => import("@/views/kota/terangtidakada.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // energi masak
      {
        name: "RTI - Energi Memasak Gas",
        path: "data-energigas",
        component: () => import("@/views/kota/energigas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Energi Memasak Minyak",
        path: "data-energiminyak",
        component: () => import("@/views/kota/energiminyak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Energi Memasak Kayu",
        path: "data-energikayu",
        component: () => import("@/views/kota/energikayu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Energi Memasak Lainnya",
        path: "data-energilain",
        component: () => import("@/views/kota/energilain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // pembuangan sampah
      {
        name: "RTI - Pembuangan Sampah Tidak Ada",
        path: "data-sampahtidakada",
        component: () => import("@/views/kota/sampahtidakada.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Sampah Kebun",
        path: "data-sampahkebun",
        component: () => import("@/views/kota/sampahkebun.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Sampah Dibakar",
        path: "data-sampahbakar",
        component: () => import("@/views/kota/sampahbakar.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Tempat Sampah ",
        path: "data-sampahtempat",
        component: () => import("@/views/kota/sampahtempat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Sampah Diangkut",
        path: "data-sampahangkut",
        component: () => import("@/views/kota/sampahangkut.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // mck
      {
        name: "RTI - Fasilitas MCK Sendiri",
        path: "data-mcksendiri",
        component: () => import("@/views/kota/mcksendiri.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas MCK kelompok",
        path: "data-mckkelompok",
        component: () => import("@/views/kota/mckkelompok.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas MCK umum",
        path: "data-mckumum",
        component: () => import("@/views/kota/mckumum.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas MCK Tidak Ada",
        path: "data-mcktidakada",
        component: () => import("@/views/kota/mcktidakada.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // sumber air mandi
      {
        name: "RTI - Sumber Air Mandi Sungai",
        path: "data-airmandisungai",
        component: () => import("@/views/kota/airmandisungai.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Mandi pipa",
        path: "data-airmandipipa",
        component: () => import("@/views/kota/airmandipipa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Mandi Tadah Air",
        path: "data-airmanditadah",
        component: () => import("@/views/kota/airmanditadah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Mandi Mata Air",
        path: "data-airmandimata",
        component: () => import("@/views/kota/airmandimata.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Mandi Lainnya",
        path: "data-airmandilain",
        component: () => import("@/views/kota/airmandilain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // BAB
      {
        name: "RTI - Fasilitas BAB Tidak Ada",
        path: "data-babtidakada",
        component: () => import("@/views/kota/babtidakada.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas BAB Sendiri",
        path: "data-babsendiri",
        component: () => import("@/views/kota/babsendiri.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas BAB umum",
        path: "data-babumum",
        component: () => import("@/views/kota/babumum.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas BAB Bersama",
        path: "data-babbersama",
        component: () => import("@/views/kota/babbersama.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Fasilitas BAB Lainnya",
        path: "data-bablain",
        component: () => import("@/views/kota/bablain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // AIR MINUM
      {
        name: "RTI - Sumber Air Minum Ledeng/Perpipaan Berbayar/Air Isi Ulang/Kemasan",
        path: "data-airminumledeng",
        component: () => import("@/views/kota/airminumledeng.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Minum Tadah Air Hujan",
        path: "data-airminumtadah",
        component: () => import("@/views/kota/airminumtadah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Minum Mata Air",
        path: "data-airminummata",
        component: () => import("@/views/kota/airminummata.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Minum Sungai",
        path: "data-airminumsungai",
        component: () => import("@/views/kota/airminumsungai.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Sumber Air Minum Lainnya",
        path: "data-airminumlain",
        component: () => import("@/views/kota/airminumlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // lIMBAH
      {
        name: "RTI - Pembuangan Limbah Cair Tangki/Instalasi Pengelolaan Limbah",
        path: "data-limbahtangki",
        component: () => import("@/views/kota/limbahtangki.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Limbah Cair Lubang",
        path: "data-limbahlubang",
        component: () => import("@/views/kota/limbahlubang.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Limbah Cair Sawah",
        path: "data-limbahsawah",
        component: () => import("@/views/kota/limbahsawah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Pembuangan Limbah Cair Lainnya",
        path: "data-limbahlain",
        component: () => import("@/views/kota/limbahlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // sutet
      {
        name: "RTI - Rumah Dekat Sutet",
        path: "data-dekatsutet",
        component: () => import("@/views/kota/dekatsutet.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Dekat Sungai",
        path: "data-dekatsungai",
        component: () => import("@/views/kota/dekatsungai.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Rumah Dekat Bukit",
        path: "data-dekatbukit",
        component: () => import("@/views/kota/dekatbukit.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // motor mobil
      {
        name: "RTI - Memiliki Kendaraan Motor",
        path: "data-milikmotor",
        component: () => import("@/views/kota/milikmotor.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Memiliki Kendaraan Mobil",
        path: "data-milikmobil",
        component: () => import("@/views/kota/milikmobil.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // jaringan
      {
        name: "RTI - Memiliki Jaringan Telepon / Internet Kabel",
        path: "data-jaringanya",
        component: () => import("@/views/kota/jaringanya.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Tidak Memiliki Jaringan Telepon / Internet Kabel",
        path: "data-jaringantidak",
        component: () => import("@/views/kota/jaringantidak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // jaringan telpon gsm
      {
        name: "RTI - Memiliki Jaringan Telepon GSM",
        path: "data-jaringangsmya",
        component: () => import("@/views/kota/jaringangsmya.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Tidak Memiliki Jaringan Telepon GSM",
        path: "data-jaringangsmtidak",
        component: () => import("@/views/kota/jaringangsmtidak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // satatus jaringan  gsm
      {
        name: "RTI - Status Jaringan GSM Cepat",
        path: "data-gsmcepat",
        component: () => import("@/views/kota/gsmcepat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Status Jaringan GSM Biasa",
        path: "data-gsmbiasa",
        component: () => import("@/views/kota/gsmbiasa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Status Jaringan GSM Lambat",
        path: "data-gsmlambat",
        component: () => import("@/views/kota/gsmlambat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTI - Status Jaringan GSM Tidak Ada",
        path: "data-gsmtidakada",
        component: () => import("@/views/kota/gsmtidakada.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // RUMAH TINGGAL KOSONG
      {
        name: "RTK - Kondisi Rumah Kumuh",
        path: "data-rtk-kumuh",
        component: () => import("@/views/kota/rtkkumuh.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "RTK - Kondisi Rumah Tidak Kumuh",
        path: "data-rtk-tidakkumuh",
        component: () => import("@/views/kota/rtktidakkumuh.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // sarana umum
      {
        name: "Sarana Umum - Jenis Sarana Umum Masjid",
        path: "data-sarana-masjid",
        component: () => import("@/views/kota/saranamasjid.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum Mushala",
        path: "data-sarana-mushala",
        component: () => import("@/views/kota/saranamushala.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum Pemerintahan",
        path: "data-sarana-pemerintah",
        component: () => import("@/views/kota/saranapemerintah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum PAUD",
        path: "data-sarana-paud",
        component: () => import("@/views/kota/saranapaud.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Jenis Sarana Umum TK",
        path: "data-sarana-tk",
        component: () => import("@/views/kota/saranatk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum SD",
        path: "data-sarana-sd",
        component: () => import("@/views/kota/saranasd.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum SMP",
        path: "data-sarana-smp",
        component: () => import("@/views/kota/saranasmp.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum SMA",
        path: "data-sarana-sma",
        component: () => import("@/views/kota/saranasma.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum Perguruan Tinggi",
        path: "data-sarana-pt",
        component: () => import("@/views/kota/saranapt.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Jenis Sarana Umum Perguruan Tinggi",
        path: "data-sarana-pt",
        component: () => import("@/views/kota/saranapt.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Sarana Umum Sekolah Agama SD Sederajat",
        path: "data-sarana-sdagama",
        component: () => import("@/views/kota/saranasdagama.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Sarana Umum Sekolah Agama SMP Sederajat",
        path: "data-sarana-smpagama",
        component: () => import("@/views/kota/saranasmpagama.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Sarana Umum Sekolah Agama SMA Sederajat",
        path: "data-sarana-smaagama",
        component: () => import("@/views/kota/saranasmaagama.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Sarana Umum Pesantren",
        path: "data-sarana-pesantren",
        component: () => import("@/views/kota/saranapesantren.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Sarana Umum Lapangan Olahraga",
        path: "data-sarana-lapangan",
        component: () => import("@/views/kota/saranalapangan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Sarana Umum Pasar",
        path: "data-sarana-pasar",
        component: () => import("@/views/kota/saranapasar.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Sarana Umum Pos Ronda",
        path: "data-sarana-pos",
        component: () => import("@/views/kota/saranapos.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Sarana Umum Jenis Bangunan",
        path: "data-sarana-bangunan",
        component: () => import("@/views/kota/saranabangunan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Sarana Umum Jenis Non Bangunan",
        path: "data-sarana-nonbangunan",
        component: () => import("@/views/kota/sarananonbangunan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Sarana Umum - Status Dibangun Negara",
        path: "data-sarana-negara",
        component: () => import("@/views/kota/sarananegara.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Status Dibangun Dana Desa",
        path: "data-sarana-danadesa",
        component: () => import("@/views/kota/saranadanadesa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Status Dibangun Masyarakat",
        path: "data-sarana-masyarakat",
        component: () => import("@/views/kota/saranamasyarakat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Status Dibangun Sumbangan Masyarakat",
        path: "data-sarana-sumbangmasyarakat",
        component: () => import("@/views/kota/saranasumbangmasyarakat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Sarana Umum - Status Lahan Tanah Negara",
        path: "data-sarana-tanahnegara",
        component: () => import("@/views/kota/saranatanahnegara.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Status Lahan Tanah adat",
        path: "data-sarana-tanahadat",
        component: () => import("@/views/kota/saranatanahadat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Status Lahan Tanah desa",
        path: "data-sarana-tanahdesa",
        component: () => import("@/views/kota/saranatanahdesa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Sarana Umum - Status Lahan Kerjasama",
        path: "data-sarana-kerjasama",
        component: () => import("@/views/kota/saranakerjasama.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      // wirausaha
      {
        name: "Wirausaha - Status Operasional Buka",
        path: "data-wirausaha-buka",
        component: () => import("@/views/kota/wirausahabuka.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Status Operasional Tutup Sementara",
        path: "data-wirausaha-tutupsementara",
        component: () => import("@/views/kota/wirausahatutupsementara.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Status Operasional Tutup Permanen",
        path: "data-wirausaha-tutuppermanen",
        component: () => import("@/views/kota/wirausahatutuppermanen.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      //
      {
        name: "Wirausaha - Sumber Modal Usaha Milik Sendiri",
        path: "data-wirausaha-modalsendiri",
        component: () => import("@/views/kota/wirausahamodalsendiri.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Sumber Modal Usaha Milik Keluarga",
        path: "data-wirausaha-modalkeluarga",
        component: () => import("@/views/kota/wirausahamodalkeluarga.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Sumber Modal Usaha Pinjaman Modal",
        path: "data-wirausaha-modalpinjam",
        component: () => import("@/views/kota/wirausahamodalpinjam.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Sumber Modal Usaha Kerjasama",
        path: "data-wirausaha-modalkerjasama",
        component: () => import("@/views/kota/wirausahamodalkerjasama.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Wirausaha - Memiliki Izin Usaha",
        path: "data-wirausaha-izin",
        component: () => import("@/views/kota/wirausahaizin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Tidak Memiliki Izin Usaha",
        path: "data-wirausaha-tidakizin",
        component: () => import("@/views/kota/wirausahatidakizin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Wirausaha - Mengenakan Pajak Kepada Konsumen",
        path: "data-wirausaha-pajak",
        component: () => import("@/views/kota/wirausahapajak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Wirausaha - Tidak Mengenakan Pajak Kepada Konsumen",
        path: "data-wirausaha-tidakpajak",
        component: () => import("@/views/kota/wirausahatidakpajak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // SPP PBB
      {
        name: "SPPT PBB - SPPT-PBB",
        path: "data-pbb",
        component: () => import("@/views/kota/pbbdata.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "SPPT PBB - SPPT-PBB Lunas",
        path: "data-pbb-lunas",
        component: () => import("@/views/kota/pbblunas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "SPPT PBB - SPPT-PBB Hutang",
        path: "data-pbb-hutang",
        component: () => import("@/views/kota/pbbhutang.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // Kependudukan
      {
        name: "Kependudukan - KK ",
        path: "data-penduduk-kk",
        component: () => import("@/views/kota/pendudukkk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK dalam Desa/Kelurahan ",
        path: "data-penduduk-kkdesa",
        component: () => import("@/views/kota/pendudukkkdesa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK dalam Desa/Kelurahan satu Kecamatan ",
        path: "data-penduduk-kkcamat",
        component: () => import("@/views/kota/pendudukkkcamat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK dalam Desa/Kelurahan beda Kecamatan satu Kota",
        path: "data-penduduk-kkkota",
        component: () => import("@/views/kota/pendudukkkkota.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Luar Kota",
        path: "data-penduduk-kkluar",
        component: () => import("@/views/kota/pendudukkkluar.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Masuk Baru",
        path: "data-penduduk-kkmasuk",
        component: () => import("@/views/kota/pendudukkkmasuk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Pemecahan",
        path: "data-penduduk-kkpecah",
        component: () => import("@/views/kota/pendudukkkpecah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Penduduk Masuk",
        path: "data-penduduk-kkpendmasuk",
        component: () => import("@/views/kota/pendudukkkpendmasuk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - KK Mampu",
        path: "data-penduduk-kkmampu",
        component: () => import("@/views/kota/pendudukkkmampu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Biasa",
        path: "data-penduduk-kkbiasa",
        component: () => import("@/views/kota/pendudukkkbiasa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Miskin",
        path: "data-penduduk-kkmiskin",
        component: () => import("@/views/kota/pendudukkkmiskin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - KK Sangat Miskin",
        path: "data-penduduk-kksangatmiskin",
        component: () => import("@/views/kota/pendudukkksangatmiskin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      //
      {
        name: "Kependudukan - Data Penduduk",
        path: "data-penduduk-semua",
        component: () => import("@/views/kota/penduduksemua.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Data Kematian",
        path: "data-penduduk-kematian",
        component: () => import("@/views/kota/pendudukkematian.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Penduduk Laki-Laki",
        path: "data-penduduk-lk",
        component: () => import("@/views/kota/penduduklk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Penduduk Perempuan",
        path: "data-penduduk-pr",
        component: () => import("@/views/kota/pendudukpr.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Penduduk Menetap",
        path: "data-penduduk-menetap",
        component: () => import("@/views/kota/pendudukmenetap.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Penduduk Merantau",
        path: "data-penduduk-merantau",
        component: () => import("@/views/kota/pendudukmerantau.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Penduduk Agama Islam",
        path: "data-penduduk-islam",
        component: () => import("@/views/kota/pendudukislam.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Penduduk Agama protestan",
        path: "data-penduduk-protestan",
        component: () => import("@/views/kota/pendudukprotestan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Penduduk Agama katolik",
        path: "data-penduduk-katolik",
        component: () => import("@/views/kota/pendudukkatolik.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Penduduk Agama hindu",
        path: "data-penduduk-hindu",
        component: () => import("@/views/kota/pendudukhindu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Penduduk Agama budha",
        path: "data-penduduk-budha",
        component: () => import("@/views/kota/pendudukbudha.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Status Perkawinan Kawin",
        path: "data-penduduk-kawin",
        component: () => import("@/views/kota/pendudukkawin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Status Perkawinan Tidak Kawin",
        path: "data-penduduk-tidakkawin",
        component: () => import("@/views/kota/penduduktidakkawin.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Status Perkawinan Janda",
        path: "data-penduduk-janda",
        component: () => import("@/views/kota/pendudukjanda.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Status Perkawinan Duda",
        path: "data-penduduk-duda",
        component: () => import("@/views/kota/pendudukduda.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Kewarganegaraan WNI",
        path: "data-penduduk-wni",
        component: () => import("@/views/kota/pendudukwni.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Kewarganegaraan WNA",
        path: "data-penduduk-wna",
        component: () => import("@/views/kota/pendudukwna.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Hak Politik ",
        path: "data-penduduk-politik",
        component: () => import("@/views/kota/pendudukpolitik.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Kondisi Pekerjaan Bekerja",
        path: "data-penduduk-kerja",
        component: () => import("@/views/kota/pendudukkerja.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Kondisi Pekerjaan Tidak Bekerja",
        path: "data-penduduk-tidakkerja",
        component: () => import("@/views/kota/penduduktidakkerja.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Kondisi Pekerjaan Mencari Kerja",
        path: "data-penduduk-carikerja",
        component: () => import("@/views/kota/pendudukcarikerja.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Kondisi Pekerjaan Sekolah",
        path: "data-penduduk-bersekolah",
        component: () => import("@/views/kota/pendudukbersekolah.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Kondisi Pekerjaan IRT",
        path: "data-penduduk-irt",
        component: () => import("@/views/kota/pendudukirt.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Pekerjaan Petani Pemilik Lahan",
        path: "data-penduduk-tanilahan",
        component: () => import("@/views/kota/penduduktanilahan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan Petani Penyewa",
        path: "data-penduduk-tanisewa",
        component: () => import("@/views/kota/penduduktanisewa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan Buruh Tani",
        path: "data-penduduk-buruhtani",
        component: () => import("@/views/kota/pendudukburuhtani.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan Pemilik Kapal/Perahu",
        path: "data-penduduk-milikkapal",
        component: () => import("@/views/kota/pendudukmilikkapal.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan Pemilik Kapal/Perahu",
        path: "data-penduduk-sewakapal",
        component: () => import("@/views/kota/penduduksewakapal.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-nelayan",
        component: () => import("@/views/kota/penduduknelayan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-guru",
        component: () => import("@/views/kota/pendudukguru.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-pedagang",
        component: () => import("@/views/kota/pendudukpedagang.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-industri",
        component: () => import("@/views/kota/pendudukindustri.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-tni",
        component: () => import("@/views/kota/penduduktni.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-pns",
        component: () => import("@/views/kota/pendudukpns.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-polisi",
        component: () => import("@/views/kota/pendudukpolisi.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-perangkatdesa",
        component: () => import("@/views/kota/pendudukperangkatdesa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-honor",
        component: () => import("@/views/kota/pendudukhonor.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-tki",
        component: () => import("@/views/kota/penduduktki.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-kesehatan",
        component: () => import("@/views/kota/pendudukkesehatan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-thl",
        component: () => import("@/views/kota/pendudukthl.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-swasta",
        component: () => import("@/views/kota/pendudukswasta.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kependudukan - Pekerjaan",
        path: "data-penduduk-kerjalain",
        component: () => import("@/views/kota/pendudukkerjalain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // Umur

      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-nol",
        component: () => import("@/views/kota/umurnol.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-lima",
        component: () => import("@/views/kota/umurlima.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-limabelas",
        component: () => import("@/views/kota/umurlimabelas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-enamlima",
        component: () => import("@/views/kota/umurenamlima.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-lknol",
        component: () => import("@/views/kota/umurlknol.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-lklima",
        component: () => import("@/views/kota/umurlklima.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-lklimabelas",
        component: () => import("@/views/kota/umurlklimabelas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-lkenamlima",
        component: () => import("@/views/kota/umurlkenamlima.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-prnol",
        component: () => import("@/views/kota/umurprnol.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-prlima",
        component: () => import("@/views/kota/umurprlima.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-prlimabelas",
        component: () => import("@/views/kota/umurprlimabelas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Penduduk Umur",
        path: "data-umur-prenamlima",
        component: () => import("@/views/kota/umurprenamlima.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // Pendidikan
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-ts",
        component: () => import("@/views/kota/pendidikants.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-sd",
        component: () => import("@/views/kota/pendidikansd.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-smp",
        component: () => import("@/views/kota/pendidikansmp.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-sma",
        component: () => import("@/views/kota/pendidikansma.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-diploma",
        component: () => import("@/views/kota/pendidikandiploma.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-ssatu",
        component: () => import("@/views/kota/pendidikanssatu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-sdua",
        component: () => import("@/views/kota/pendidikansdua.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-stiga",
        component: () => import("@/views/kota/pendidikanstiga.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-pesantren",
        component: () => import("@/views/kota/pendidikanpesantren.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Pendidikan - Penduduk Pendidikan",
        path: "data-pendidikan-lain",
        component: () => import("@/views/kota/pendidikanlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // beasiswa
      {
        name: "Beasiswa - Beasiswa",
        path: "data-beasiswa-saga",
        component: () => import("@/views/kota/beasiswasaga.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // bantuan sosial
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-bltdesa",
        component: () => import("@/views/kota/bantuanbltdesa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-bltkota",
        component: () => import("@/views/kota/bantuanbltkota.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-bltprov",
        component: () => import("@/views/kota/bantuanbltprov.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-pkh",
        component: () => import("@/views/kota/bantuanpkh.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // {
      //   name: "Bantuan Sosial - Bantuan Sosial",
      //   path: "data-bantuan-bst",
      //   component: () => import("@/views/kota/bantuanbst.vue"),
      //   meta: {
      //     kota: true,
      //     login: true,
      //   },
      // },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-bpnt",
        component: () => import("@/views/kota/bantuanbpnt.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-presiden",
        component: () => import("@/views/kota/bantuanpresiden.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-umkm",
        component: () => import("@/views/kota/bantuanumkm.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-pekerja",
        component: () => import("@/views/kota/bantuanpekerja.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-pangan",
        component: () => import("@/views/kota/bantuanpangan.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Bantuan Sosial - Bantuan Sosial",
        path: "data-bantuan-lain",
        component: () => import("@/views/kota/bantuanlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // Kesehatan
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-semua",
        component: () => import("@/views/kota/sehatsemua.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-kis",
        component: () => import("@/views/kota/sehatkis.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-bpjsm",
        component: () => import("@/views/kota/sehatbpjsm.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-bpjsk",
        component: () => import("@/views/kota/sehatbpjsk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-lain",
        component: () => import("@/views/kota/sehatlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-tidak",
        component: () => import("@/views/kota/sehattidak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-stunting",
        component: () => import("@/views/kota/sehatstunting.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-disabilitas",
        component: () => import("@/views/kota/sehatdisabilitas.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-buta",
        component: () => import("@/views/kota/sehatbuta.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-tuli",
        component: () => import("@/views/kota/sehattuli.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-bisu",
        component: () => import("@/views/kota/sehatbisu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-cacat",
        component: () => import("@/views/kota/sehatcacat.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-mental",
        component: () => import("@/views/kota/sehatmental.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-jiwa",
        component: () => import("@/views/kota/sehatjiwa.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-kusta",
        component: () => import("@/views/kota/sehatkusta.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-ganda",
        component: () => import("@/views/kota/sehatganda.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-pasung",
        component: () => import("@/views/kota/sehatpasung.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-muntaber",
        component: () => import("@/views/kota/sehatmuntaber.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-dbd",
        component: () => import("@/views/kota/sehatdbd.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-campak",
        component: () => import("@/views/kota/sehatcampak.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-malaria",
        component: () => import("@/views/kota/sehatmalaria.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-flu",
        component: () => import("@/views/kota/sehatflu.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-covid",
        component: () => import("@/views/kota/sehatcovid.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-hepab",
        component: () => import("@/views/kota/sehathepab.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-hepae",
        component: () => import("@/views/kota/sehathepae.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-difetri",
        component: () => import("@/views/kota/sehatdifetri.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-ciku",
        component: () => import("@/views/kota/sehatciku.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-lepto",
        component: () => import("@/views/kota/sehatlepto.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-kolera",
        component: () => import("@/views/kota/sehatkolera.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-gizi",
        component: () => import("@/views/kota/sehatgizi.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-jantung",
        component: () => import("@/views/kota/sehatjantung.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-tbc",
        component: () => import("@/views/kota/sehattbc.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-kanker",
        component: () => import("@/views/kota/sehatkanker.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      //
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-diabetes",
        component: () => import("@/views/kota/sehatdiabetes.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-lumpuh",
        component: () => import("@/views/kota/sehatlumpuh.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-stroke",
        component: () => import("@/views/kota/sehatstroke.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Kesehatan - Kesehatan",
        path: "data-sehat-sakitlain",
        component: () => import("@/views/kota/sehatsakitlain.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      // Umur baru
      {
        name: "Umur - Umur per 1 sd 75",
        path: "data-umur-perumur",
        component: () => import("@/views/kota/umurperumur.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Umur per 1 sd 75",
        path: "data-umur-perumurlk",
        component: () => import("@/views/kota/umurperumurlk.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "Umur - Umur per 1 sd 75",
        path: "data-umur-perumurpr",
        component: () => import("@/views/kota/umurperumurpr.vue"),
        meta: {
          kota: true,
          login: true,
        },
      },

      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },
  // RUTE KECAMATAN
  {
    path: "/admin-kecamatan",
    name: "Admin Kecamatan",
    component: AppLayout,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeKecamatan,
        meta: {
          kecamatan: true,
          login: true,
        },
      },
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  // RUTE MARDIAN
  {
    path: "/admin-user",
    name: "Admin",
    component: MardianLay,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeUser,
        meta: {
          provinsi: true,
          login: true,
        },
      },
      {
        name: "Bangunan",
        path: "bangunan",
        component: BangunanUser,
        meta: {
          provinsi: true,
          login: true,
        },
      },
      {
        name: "Penduduk",
        path: "penduduk",
        component: PendudukUser,
        meta: {
          provinsi: true,
          login: true,
        },
      },
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  {
    path: "/404",
    component: Notfound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  // next();
  const status = JSON.parse(window.localStorage.getItem("lbUser"));
  if (to.meta.login) {
    if (to.meta.superadmin && status === "superadmin") {
      next();
    } else if (to.meta.provinsi && status === "adminuser") {
      next();
    } else if (to.meta.kota && status === "kota") {
      next();
    } else if (to.meta.kecamatan && status === "kecamatan") {
      next();
    } else if (to.meta.kelurahan && status === "kelurahan") {
      next();
    } else {
      next({ name: "erorr4040" });
    }
  } else {
    next();
  }
  // console.log(status);
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
