import { render, staticRenderFns } from "./StsSaga.vue?vue&type=template&id=486a8e94&scoped=true&"
import script from "./StsSaga.vue?vue&type=script&lang=js&"
export * from "./StsSaga.vue?vue&type=script&lang=js&"
import style0 from "./StsSaga.vue?vue&type=style&index=0&id=486a8e94&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486a8e94",
  null
  
)

export default component.exports