<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Data Statistik - Bantuan Sosial</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="blue"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <!-- jenis jalan -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK BANTUAN SOSIAL</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <ChartMap
                      v-bind:databaru="hasildatas.data.bantuan_sosial"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kecamatan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata[0].name
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <br />
                        <ChartMap
                          v-bind:databaru="itemdata[0].data.bantuan_sosial"
                        />

                        <!-- Kelurahan -->
                        <v-expansion-panels hover accordion>
                          <v-expansion-panel
                            v-for="(itemdatas, indexs) in itemdata[0].kelurahan"
                            :key="indexs"
                          >
                            <v-expansion-panel-header
                              ><strong>{{
                                itemdatas.total
                              }}</strong></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <br />
                              <ChartMap
                                v-bind:databaru="itemdatas.data.bantuan_sosial"
                              />
                            </v-expansion-panel-content> </v-expansion-panel
                        ></v-expansion-panels>
                        <!--end Kelurahan-->
                      </v-expansion-panel-content>
                    </v-expansion-panel></v-expansion-panels
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";

import ChartMap from "@/components/ChartMap.vue";

export default {
  name: "PublikLahan",
  components: { ChartMap },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,
      panel: [0],
      // chart DUA
      chartDatadua: "",
      chartDataKelSatu: [],
      chartDataKelDua: [],
      // Data
      hasildatas: [{}],

      // Chart

      series: [44, 55, 41, 17, 15],

      chartData: "",
      chartOptions: {
        responsive: true,

        maintainAspectRatio: true,
        cutoutPercentage: 80,

        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
            // callbacks: {
            //   label: function (context) {
            //     let label = context.label;
            //     let value = context.formattedValue;

            //     if (!label) label = "Unknown";

            //     let sum = 0;
            //     let dataArr = context.chart.data.datasets[0].data;
            //     dataArr.map((data) => {
            //       sum += Number(data);
            //     });

            //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
            //     return label + ": " + percentage;
            //   },
            // },
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataBantuanSosial();
  },
  methods: {
    stsDataBantuanSosial: function () {
      ServicePublik.stsDataBantuanSosial().then(
        function (response) {
          this.hasildatas = response.data.data[0];

          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
