<template>
  <v-app id="inspire">
    <v-navigation-drawer width="300" fixed v-model="drawer" app>
      <v-sheet color="white lighten-4" class="pa-3">
        <!-- <v-avatar class="mb-4" size="62">
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
        </v-avatar> -->
        <v-container class="py-0 fill-height">
          <v-img
            max-height="42px"
            max-width="33px"
            src="@/assets/prm.png"
          ></v-img>

          <h3 class="text-uppercase pl-2">WALI{{ role }}</h3>
          <v-spacer></v-spacer>
        </v-container>
      </v-sheet>

      <v-divider></v-divider>
      <v-list v-show="role === 'superadmin'">
        <v-list-item active-class="teal--text" to="/dashboard/beranda" link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>

        <v-list-group
          active-class="teal--text"
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-show="role === 'provinsi'">
        <v-list-item
          active-class="teal--text"
          to="/admin-provinsi/beranda"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list dense>
        <v-list-item active-class="teal--text" to="/admin-kota/beranda" link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
      </v-list>
      <h6 class="pa-3 grey--text">Data Master</h6>
      <v-list dense>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemsdua"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemstiga"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemsempat"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemslima"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemsenam"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemstujuh"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- umur -->
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemslapan"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemslimabelas"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemstigabelas"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- Kesehatan -->
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemsdualas"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- pendidikan -->
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemssembilan"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- beasiswa pendidkan -->
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemspuluh"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- bantuan sosial -->
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in itemssebelas"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                child.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar elevation="2" app dark color="white" class="black--text">
      <v-app-bar-nav-icon
        color="black"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-divider class="mx-4" vertical></v-divider>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar v-bind="attrs" v-on="on">
            <v-img src="@/assets/ava.jpg"></v-img>
          </v-list-item-avatar>
          <h4>Hai. {{ nama }}</h4>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img src="@/assets/ava.jpg"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ nama }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group color="teal">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pengaturan Akun</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profil Saya</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list nav dense>
          <v-list-item-group color="teal">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn
                    block
                    class="teal--text font-weight-bold"
                    @click="logout"
                    >LogOut</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import AuthService from "@/services/AuthService.js";
export default {
  name: "AppLayout",
  data: () => ({
    dialog: false,
    nama: "",
    role: "",
    email: "",
    drawer: true,

    items: [
      {
        title: "Bangunan",
        action: "mdi-infinity",
        items: [
          { title: "Rumah Tinggal Isi", to: "/admin-kota/data-rti" },
          { title: "Rumah Tinggal Kosong", to: "/admin-kota/data-rtk" },
          { title: "Wirausaha", to: "/admin-kota/data-wirausaha" },
          { title: "Sarana Umum", to: "/admin-kota/data-sarana" },
        ],
      },
    ],
    itemsdua: [
      {
        title: "Rumah Tinggal Isi",
        action: "mdi-infinity",
        items: [
          { title: "Rumah Kumuh", to: "/admin-kota/data-rumahkumuh" },
          {
            title: "Rumah Tidak Kumuh",
            to: "/admin-kota/data-rumahtidakkumuh",
          },
          {
            title: "Rumah Milik Sendiri",
            to: "/admin-kota/data-rumahmiliksendiri",
          },
          {
            title: "Rumah Sewa",
            to: "/admin-kota/data-rumahsewa",
          },
          {
            title: "Rumah Bebas Sewa",
            to: "/admin-kota/data-bebassewa",
          },
          {
            title: "Rumah Dipinjami",
            to: "/admin-kota/data-rumahpinjam",
          },
          {
            title: "Rumah Dinas",
            to: "/admin-kota/data-rumahdinas",
          },
          {
            title: "Rumah Lainnya",
            to: "/admin-kota/data-rumahlain",
          },
          //
          {
            title: "Lahan Milik Sendiri",
            to: "/admin-kota/data-lahanmilik",
          },
          {
            title: "Lahan Milik Orang Lain",
            to: "/admin-kota/data-lahanorang",
          },
          {
            title: "Lahan Tanah Negara",
            to: "/admin-kota/data-lahannegara",
          },
          {
            title: "Lahan Lainnya",
            to: "/admin-kota/data-lahanlain",
          },
          // Lantai
          {
            title: "Lantai Marmer",
            to: "/admin-kota/data-lantaimarmer",
          },
          {
            title: "Lantai Papan Tinggi",
            to: "/admin-kota/data-lantaipapantinggi",
          },
          {
            title: "Lantai Keramik",
            to: "/admin-kota/data-lantaikeramik",
          },
          {
            title: "Lantai Semen",
            to: "/admin-kota/data-lantaisemen",
          },
          {
            title: "Lantai Parker",
            to: "/admin-kota/data-lantaiparket",
          },
          {
            title: "Lantai Bambu",
            to: "/admin-kota/data-lantaibambu",
          },
          {
            title: "Lantai Ubin",
            to: "/admin-kota/data-lantaiubin",
          },
          {
            title: "Lantai Papan Rendah",
            to: "/admin-kota/data-lantaipapanrendah",
          },
          {
            title: "Lantai Lainnya",
            to: "/admin-kota/data-lantailain",
          },
          // dinding
          {
            title: "Dinding Semen",
            to: "/admin-kota/data-dindingsemen",
          },
          {
            title: "Dinding Kayu",
            to: "/admin-kota/data-dindingkayu",
          },
          {
            title: "Dinding Lainnya",
            to: "/admin-kota/data-dindinglain",
          },
          // jendela
          {
            title: "Jendela Berfungsi",
            to: "/admin-kota/data-jendelafungsi",
          },
          {
            title: "Jendela Tidak Berfungsi",
            to: "/admin-kota/data-jendelatidakfungsi",
          },
          {
            title: "Jendela Tidak Ada",
            to: "/admin-kota/data-jendelatidakada",
          },
          // atap
          {
            title: "Atap Genteng",
            to: "/admin-kota/data-atapgenteng",
          },
          {
            title: "Atap Seng",
            to: "/admin-kota/data-atapseng",
          },
          {
            title: "Atap Kayu",
            to: "/admin-kota/data-atapkayu",
          },
          {
            title: "Atap Lainnya",
            to: "/admin-kota/data-ataplain",
          },
          // penerangan
          {
            title: "Penerangan PLN",
            to: "/admin-kota/data-terangpln",
          },
          {
            title: "Penerangan Non PLN",
            to: "/admin-kota/data-terangnonpln",
          },
          {
            title: "Penerangan Lampu Minyak",
            to: "/admin-kota/data-terangminyak",
          },
          {
            title: "Penerangan Sumber Lainnya",
            to: "/admin-kota/data-terangsumber",
          },
          {
            title: "Penerangan Tidak Ada",
            to: "/admin-kota/data-terangtidakada",
          },

          // energi masak
          {
            title: "Energi Memasak Gas",
            to: "/admin-kota/data-energigas",
          },
          {
            title: "Energi Memasak Kayu",
            to: "/admin-kota/data-energikayu",
          },
          {
            title: "Energi Memasak Minyak",
            to: "/admin-kota/data-energiminyak",
          },
          {
            title: "Energi Memasak Lainnya",
            to: "/admin-kota/data-energilain",
          },

          // pembuang sampah
          {
            title: "Pembuangan Sampah Tidak Ada",
            to: "/admin-kota/data-sampahtidakada",
          },
          {
            title: "Pembuangan Sampah Dikebun/Sungai/Drainease",
            to: "/admin-kota/data-sampahkebun",
          },
          {
            title: "Pembuangan Sampah Dibakar",
            to: "/admin-kota/data-sampahbakar",
          },
          {
            title: "Pembuangan Tempat Sampah",
            to: "/admin-kota/data-sampahtempat",
          },
          {
            title: "Pembuangan Samah Diangkut",
            to: "/admin-kota/data-sampahangkut",
          },

          // mck
          {
            title: "Fasilitas MCK Sendiri",
            to: "/admin-kota/data-mcksendiri",
          },
          {
            title: "Fasilitas MCK Kelompok",
            to: "/admin-kota/data-mckkelompok",
          },
          {
            title: "Fasilitas MCK Umum",
            to: "/admin-kota/data-mckumum",
          },
          {
            title: "Fasilitas MCK Tidak Ada",
            to: "/admin-kota/data-mcktidakada",
          },

          // sumber air mandi
          {
            title: "Sumber Air Mandi Sungai,Danau,Embung",
            to: "/admin-kota/data-airmandisungai",
          },
          {
            title: "Sumber Air Mandi Perpipaan",
            to: "/admin-kota/data-airmandipipa",
          },
          {
            title: "Sumber Air Mandi Tadah Air",
            to: "/admin-kota/data-airmanditadah",
          },
          {
            title: "Sumber Air Mandi Mata Air",
            to: "/admin-kota/data-airmandimata",
          },
          {
            title: "Sumber Air Mandi Lainnya",
            to: "/admin-kota/data-airmandilain",
          },

          // bab
          {
            title: "Fasilitas BAB Tidak Ada",
            to: "/admin-kota/data-babtidakada",
          },
          {
            title: "Fasilitas BAB Sendiri",
            to: "/admin-kota/data-babsendiri",
          },
          {
            title: "Fasilitas BAB Umum",
            to: "/admin-kota/data-babumum",
          },
          {
            title: "Fasilitas BAB Bersama",
            to: "/admin-kota/data-babbersama",
          },
          {
            title: "Fasilitas BAB Lainnya",
            to: "/admin-kota/data-bablain",
          },

          // air minum
          {
            title:
              "Sumber Air Minum Ledeng/Perpipaan Berbayar/Air Isi Ulang/Kemasan",
            to: "/admin-kota/data-airminumledeng",
          },
          {
            title: "Sumber Air Minum Tadah Air Hujan",
            to: "/admin-kota/data-airminumtadah",
          },
          {
            title: "Sumber Air Minum Mata Air",
            to: "/admin-kota/data-airminummata",
          },
          {
            title: "Sumber Air Minum Sungai",
            to: "/admin-kota/data-airminumsungai",
          },
          {
            title: "Sumber Air Minum Lainnya",
            to: "/admin-kota/data-airminumlain",
          },

          // limbah
          {
            title: "Pembuangan Limbah Cair Tangki/Instalasi Pengelolaan Limbah",
            to: "/admin-kota/data-limbahtangki",
          },
          {
            title: "Pembuangan Limbah Cair Lubang",
            to: "/admin-kota/data-limbahlubang",
          },
          {
            title: "Pembuangan Limbah Cair Sawah",
            to: "/admin-kota/data-limbahsawah",
          },
          {
            title: "Pembuangan Limbah Cair Lainnya",
            to: "/admin-kota/data-limbahlain",
          },

          // Sutet
          {
            title: "Rumah Dekat Sutet",
            to: "/admin-kota/data-dekatsutet",
          },
          {
            title: "Rumah Dekat Sungai",
            to: "/admin-kota/data-dekatsungai",
          },
          {
            title: "Rumah Dekat Bukit",
            to: "/admin-kota/data-dekatbukit",
          },
          // mobi motor
          {
            title: "Memiliki Kendaraan Motor",
            to: "/admin-kota/data-milikmotor",
          },
          {
            title: "Memiliki Kendaraan Mobil",
            to: "/admin-kota/data-milikmobil",
          },

          // Jaringan telpon
          {
            title: "Memiliki Jaringan Telpon / Internet Kabel",
            to: "/admin-kota/data-jaringanya",
          },
          {
            title: "Tidak Memiliki Jaringan Telpon / Internet Kabel",
            to: "/admin-kota/data-jaringantidak",
          },

          // Jaringan telpon gsm
          {
            title: "Memiliki Jaringan Telpon GSM",
            to: "/admin-kota/data-jaringangsmya",
          },
          {
            title: "Tidak Memiliki Jaringan Telpon GSM",
            to: "/admin-kota/data-jaringangsmtidak",
          },

          // Statu Jaringan gsm
          {
            title: "Status Jaringan GSM Cepat",
            to: "/admin-kota/data-gsmcepat",
          },
          {
            title: "Status Jaringan GSM Biasa",
            to: "/admin-kota/data-gsmbiasa",
          },
          {
            title: "Status Jaringan GSM Lambat",
            to: "/admin-kota/data-gsmlambat",
          },
          {
            title: "Status Jaringan GSM Tidak Ada",
            to: "/admin-kota/data-gsmtidakada",
          },
        ],
      },
    ],
    itemstiga: [
      {
        title: "Rumah Tinggal Kosong",
        action: "mdi-infinity",
        items: [
          { title: "Rumah Kumuh", to: "/admin-kota/data-rtk-kumuh" },
          { title: "Rumah Tidak Kumuh", to: "/admin-kota/data-rtk-tidakkumuh" },
        ],
      },
    ],
    itemsempat: [
      {
        title: "Sarana Umum",
        action: "mdi-infinity",
        items: [
          {
            title: "Sarana Umum Masjid",
            to: "/admin-kota/data-sarana-masjid",
          },
          {
            title: "Sarana Umum Mushala",
            to: "/admin-kota/data-sarana-mushala",
          },
          {
            title: "Sarana Umum Kantor Pemerintahan",
            to: "/admin-kota/data-sarana-pemerintah",
          },
          {
            title: "Sarana Umum Paud",
            to: "/admin-kota/data-sarana-paud",
          },
          {
            title: "Sarana Umum TK",
            to: "/admin-kota/data-sarana-tk",
          },
          {
            title: "Sarana Umum SD",
            to: "/admin-kota/data-sarana-sd",
          },
          {
            title: "Sarana Umum SMP",
            to: "/admin-kota/data-sarana-smp",
          },
          {
            title: "Sarana Umum SMA",
            to: "/admin-kota/data-sarana-sma",
          },
          {
            title: "Sarana Umum Perguruan Tinggi",
            to: "/admin-kota/data-sarana-pt",
          },
          {
            title: "Sarana Umum Sekolah Agama SD Sederajat",
            to: "/admin-kota/data-sarana-sdagama",
          },
          {
            title: "Sarana Umum Sekolah Agama SMP Sederajat",
            to: "/admin-kota/data-sarana-smpagama",
          },
          {
            title: "Sarana Umum Sekolah Agama SMA Sederajat",
            to: "/admin-kota/data-sarana-smaagama",
          },

          {
            title: "Sarana Umum Pesantren",
            to: "/admin-kota/data-sarana-pesantren",
          },
          {
            title: "Sarana Umum Lapangan Olahraga",
            to: "/admin-kota/data-sarana-lapangan",
          },
          {
            title: "Sarana Umum Pasar",
            to: "/admin-kota/data-sarana-pasar",
          },
          {
            title: "Sarana Umum Pos Ronda",
            to: "/admin-kota/data-sarana-pos",
          },
          {
            title: "Sarana Umum Jenis Bangunan",
            to: "/admin-kota/data-sarana-bangunan",
          },
          {
            title: "Sarana Umum Jenis Non Bangunan",
            to: "/admin-kota/data-sarana-nonbangunan",
          },
          //
          {
            title: "Status Bangunan Dibangun Negara",
            to: "/admin-kota/data-sarana-negara",
          },
          {
            title: "Status Bangunan Dibangun Dana Desa",
            to: "/admin-kota/data-sarana-danadesa",
          },
          {
            title: "Status Bangunan Dibangun Masyrakat",
            to: "/admin-kota/data-sarana-masyarakat",
          },
          {
            title: "Status Bangunan Dibangun Sumbangan Masyrakat",
            to: "/admin-kota/data-sarana-sumbangmasyarakat",
          },
          //
          {
            title: "Status Lahan Tanah Negara",
            to: "/admin-kota/data-sarana-tanahnegara",
          },
          {
            title: "Status Lahan Tanah Ulayat/Adat",
            to: "/admin-kota/data-sarana-tanahadat",
          },
          {
            title: "Status Lahan Tanah Desa",
            to: "/admin-kota/data-sarana-tanahdesa",
          },
          {
            title: "Status Lahan Kerjasama",
            to: "/admin-kota/data-sarana-kerjasama",
          },
        ],
      },
    ],
    itemslima: [
      {
        title: "Wirausaha",
        action: "mdi-infinity",
        items: [
          {
            title: "Status Operasional Buka",
            to: "/admin-kota/data-wirausaha-buka",
          },
          {
            title: "Status Operasional Tutup Sementara",
            to: "/admin-kota/data-wirausaha-tutupsementara",
          },
          {
            title: "Status Operasional Tutup Permanen",
            to: "/admin-kota/data-wirausaha-tutuppermanen",
          },
          //
          {
            title: "Sumber Modal Usaha Milik Sendiri",
            to: "/admin-kota/data-wirausaha-modalsendiri",
          },
          {
            title: "Sumber Modal Usaha Milik Keluarga",
            to: "/admin-kota/data-wirausaha-modalkeluarga",
          },
          {
            title: "Sumber Modal Usaha Pinjaman Modal",
            to: "/admin-kota/data-wirausaha-modalpinjam",
          },
          {
            title: "Sumber Modal Usaha Kerjasama",
            to: "/admin-kota/data-wirausaha-modalkerjasama",
          },

          //
          {
            title: "Memiliki Izin Usaha",
            to: "/admin-kota/data-wirausaha-izin",
          },
          {
            title: "Tidak Memiliki Izin Usaha",
            to: "/admin-kota/data-wirausaha-tidakizin",
          },
          {
            title: "Mengenakan Pajak Kepada Konsumen",
            to: "/admin-kota/data-wirausaha-pajak",
          },
          {
            title: "Tidak Mengenakan Pajak Kepada Konsumen",
            to: "/admin-kota/data-wirausaha-tidakpajak",
          },
        ],
      },
    ],
    itemsenam: [
      {
        title: "SPPT-PBB",
        action: "mdi-infinity",
        items: [
          { title: "SPPT-PBB", to: "/admin-kota/data-pbb" },
          { title: "SPPT-PBB Lunas", to: "/admin-kota/data-pbb-lunas" },
          { title: "SPPT-PBB Hutang", to: "/admin-kota/data-pbb-hutang" },
        ],
      },
    ],
    itemstujuh: [
      {
        title: "Kependudukan",
        action: "mdi-infinity",
        items: [
          {
            title: "Data Semua KK",
            to: "/admin-kota/data-penduduk-kk",
          },
          {
            title: "KK dalam Desa/Kelurahan",
            to: "/admin-kota/data-penduduk-kkdesa",
          },
          {
            title: "KK dalam Desa/Kelurahan di satu Kecamatan",
            to: "/admin-kota/data-penduduk-kkcamat",
          },
          {
            title: "KK dalam Desa/Kelurahan beda Kecamatan satu Kota",
            to: "/admin-kota/data-penduduk-kkkota",
          },
          {
            title: "KK Luar Kota",
            to: "/admin-kota/data-penduduk-kkluar",
          },

          //
          {
            title: "Status Kemiskinan Mampu",
            to: "/admin-kota/data-penduduk-kkmampu",
          },
          {
            title: "Status Kemiskinan Biasa",
            to: "/admin-kota/data-penduduk-kkbiasa",
          },
          {
            title: "Status Kemiskinan Miskin",
            to: "/admin-kota/data-penduduk-kkmiskin",
          },
          {
            title: "Status Kemiskinan Sangat Miskin",
            to: "/admin-kota/data-penduduk-kksangatmiskin",
          },
          {
            title: "Data Penduduk",
            to: "/admin-kota/data-penduduk-semua",
          },

          {
            title: "Penduduk Laki-Laki",
            to: "/admin-kota/data-penduduk-lk",
          },
          {
            title: "Penduduk Perempuan",
            to: "/admin-kota/data-penduduk-pr",
          },
          //
          {
            title: "Penduduk Menetap",
            to: "/admin-kota/data-penduduk-menetap",
          },
          {
            title: "Penduduk Merantau",
            to: "/admin-kota/data-penduduk-merantau",
          },
          //
          {
            title: "Penduduk Agama Islam",
            to: "/admin-kota/data-penduduk-islam",
          },
          {
            title: "Penduduk Agama Protestan",
            to: "/admin-kota/data-penduduk-protestan",
          },
          {
            title: "Penduduk Agama Katolik",
            to: "/admin-kota/data-penduduk-katolik",
          },
          {
            title: "Penduduk Agama Hindu",
            to: "/admin-kota/data-penduduk-hindu",
          },
          {
            title: "Penduduk Agama Budha",
            to: "/admin-kota/data-penduduk-budha",
          },
          //
          {
            title: "Status Perkawinan Kawin",
            to: "/admin-kota/data-penduduk-kawin",
          },
          {
            title: "Status Perkawinan Tidak Kawin",
            to: "/admin-kota/data-penduduk-tidakkawin",
          },
          {
            title: "Status Perkawinan Janda",
            to: "/admin-kota/data-penduduk-janda",
          },
          {
            title: "Status Perkawinan Duda",
            to: "/admin-kota/data-penduduk-duda",
          },
          {
            title: "Kewarganegaraan WNI",
            to: "/admin-kota/data-penduduk-wni",
          },
          {
            title: "Kewarganegaraan WNA",
            to: "/admin-kota/data-penduduk-wna",
          },
          {
            title: "Hak Politik",
            to: "/admin-kota/data-penduduk-politik",
          },
          //
          {
            title: "Kondisi Pekerjaan Bekerja",
            to: "/admin-kota/data-penduduk-kerja",
          },
          {
            title: "Kondisi Pekerjaan Tidak Bekerja",
            to: "/admin-kota/data-penduduk-tidakkerja",
          },
          {
            title: "Kondisi Pekerjaan Sedang Mencari Pekerjaan",
            to: "/admin-kota/data-penduduk-carikerja",
          },

          {
            title: "Kondisi Pekerjaan Bersekolah",
            to: "/admin-kota/data-penduduk-bersekolah",
          },
          {
            title: "Kondisi Pekerjaan Mengurus Rumah Tangga",
            to: "/admin-kota/data-penduduk-irt",
          },
          // pekerjaan
          {
            title: "Pekerjaan Petani Pemilik Lahan",
            to: "/admin-kota/data-penduduk-tanilahan",
          },
          {
            title: "Pekerjaan Petani Penyewa",
            to: "/admin-kota/data-penduduk-tanisewa",
          },
          {
            title: "Pekerjaan Buruh Tani",
            to: "/admin-kota/data-penduduk-buruhtani",
          },
          {
            title: "Pekerjaan Nelayan Pemilik Kapal/Perahu",
            to: "/admin-kota/data-penduduk-milikkapal",
          },
          {
            title: "Pekerjaan Nelayan Penyewa Kapal/Perahu",
            to: "/admin-kota/data-penduduk-sewakapal",
          },
          //
          {
            title: "Pekerjaan Buruh Nelayan ",
            to: "/admin-kota/data-penduduk-nelayan",
          },
          {
            title: "Pekerjaan Guru ",
            to: "/admin-kota/data-penduduk-guru",
          },
          {
            title: "Pekerjaan Pedagang ",
            to: "/admin-kota/data-penduduk-pedagang",
          },
          {
            title: "Pekerjaan Pengolahan/Industri ",
            to: "/admin-kota/data-penduduk-industri",
          },
          {
            title: "Pekerjaan TNI ",
            to: "/admin-kota/data-penduduk-tni",
          },
          //
          {
            title: "Pekerjaan PNS ",
            to: "/admin-kota/data-penduduk-pns",
          },
          {
            title: "Pekerjaan Polisi ",
            to: "/admin-kota/data-penduduk-polisi",
          },
          {
            title: "Pekerjaan Perangkat Desa ",
            to: "/admin-kota/data-penduduk-perangkatdesa",
          },
          {
            title: "Pekerjaan Pegawai Honorer Pemda ",
            to: "/admin-kota/data-penduduk-honor",
          },
          {
            title: "Pekerjaan TKI ",
            to: "/admin-kota/data-penduduk-tki",
          },
          //
          {
            title: "Pekerjaan Tenaga Kesehatan ",
            to: "/admin-kota/data-penduduk-kesehatan",
          },
          {
            title: "Pekerjaan Buruh Harian Lepas ",
            to: "/admin-kota/data-penduduk-thl",
          },
          {
            title: "Pekerjaan Karyawan Swasta ",
            to: "/admin-kota/data-penduduk-swasta",
          },
          {
            title: "Pekerjaan Lainnya ",
            to: "/admin-kota/data-penduduk-kerjalain",
          },
        ],
      },
    ],
    itemslapan: [
      {
        title: "Umur",
        action: "mdi-infinity",
        items: [
          { title: "Penduduk Umur 0-4", to: "/admin-kota/data-umur-nol" },
          { title: "Penduduk Umur 5-14", to: "/admin-kota/data-umur-lima" },
          {
            title: "Penduduk Umur 15-64",
            to: "/admin-kota/data-umur-limabelas",
          },
          { title: "Penduduk Umur 65 +", to: "/admin-kota/data-umur-enamlima" },
          //
          {
            title: "Penduduk Laki-Laki Umur 0-4",
            to: "/admin-kota/data-umur-lknol",
          },
          {
            title: "Penduduk Laki-Laki Umur 5-14",
            to: "/admin-kota/data-umur-lklima",
          },
          {
            title: "Penduduk Laki-Laki Umur 15-64",
            to: "/admin-kota/data-umur-lklimabelas",
          },
          {
            title: "Penduduk Laki-Laki Umur 65 +",
            to: "/admin-kota/data-umur-lkenamlima",
          },

          {
            title: "Penduduk Perempuan Umur 0-4",
            to: "/admin-kota/data-umur-prnol",
          },
          {
            title: "Penduduk Perempuan Umur 5-14",
            to: "/admin-kota/data-umur-prlima",
          },
          {
            title: "Penduduk Perempuan Umur 15-64",
            to: "/admin-kota/data-umur-prlimabelas",
          },
          {
            title: "Penduduk Perempuan Umur 65 +",
            to: "/admin-kota/data-umur-prenamlima",
          },
          //
          {
            title: "Penduduk per Umur 1 sd 75 dan 75 +",
            to: "/admin-kota/data-umur-perumur",
          },
          {
            title: "Laki Laki per Umur 1 sd 75 dan 75 +",
            to: "/admin-kota/data-umur-perumurlk",
          },
          {
            title: "Wanita per Umur 1 sd 75 dan 75 +",
            to: "/admin-kota/data-umur-perumurpr",
          },
        ],
      },
    ],
    itemssembilan: [
      {
        title: "Pendidikan",
        action: "mdi-infinity",
        items: [
          {
            title: "Tidak Sekolah",
            to: "/admin-kota/data-pendidikan-ts",
          },
          { title: "SD dan Sederajat", to: "/admin-kota/data-pendidikan-sd" },
          {
            title: "SMP dan Sederajat",
            to: "/admin-kota/data-pendidikan-smp",
          },
          {
            title: "SMA dan Sederajat",
            to: "/admin-kota/data-pendidikan-sma",
          },
          {
            title: "Diploma 1-3",
            to: "/admin-kota/data-pendidikan-diploma",
          },
          {
            title: "S1 dan Sederajat",
            to: "/admin-kota/data-pendidikan-ssatu",
          },
          {
            title: "S2 dan Sederajat",
            to: "/admin-kota/data-pendidikan-sdua",
          },
          {
            title: "S3 dan Sederajat",
            to: "/admin-kota/data-pendidikan-stiga",
          },

          {
            title: "Pesantren, seminari , wihara dan sejenisnya",
            to: "/admin-kota/data-pendidikan-pesantren",
          },
          {
            title: "Sekolah Lainnya",
            to: "/admin-kota/data-pendidikan-lain",
          },
        ],
      },
    ],
    itemspuluh: [
      {
        title: "Beasiswa Pendidikan",
        action: "mdi-infinity",
        items: [
          {
            title: "Penerima Beasiswa SAGASAJA",
            to: "/admin-kota/data-beasiswa-saga",
          },
        ],
      },
    ],
    itemssebelas: [
      {
        title: "Bantuan Sosial",
        action: "mdi-infinity",
        items: [
          {
            title: "Penerima BLT dana Desa",
            to: "/admin-kota/data-bantuan-bltdesa",
          },
          {
            title: "Penerima BLT dana Kota",
            to: "/admin-kota/data-bantuan-bltkota",
          },
          {
            title: "Penerima BLT dana Provinsi",
            to: "/admin-kota/data-bantuan-bltprov",
          },
          {
            title: "Penerima Program Keluarga Harapan ( PKH )",
            to: "/admin-kota/data-bantuan-pkh",
          },
          // {
          //   title: "Penerima Bantuan Sosial Tunai",
          //   to: "/admin-kota/data-bantuan-bst",
          // },
          {
            title: "Penerima BPNT",
            to: "/admin-kota/data-bantuan-bpnt",
          },
          {
            title: "Penerima Bantuan Presiden",
            to: "/admin-kota/data-bantuan-presiden",
          },
          {
            title: "Penerima Bantuan UMKM",
            to: "/admin-kota/data-bantuan-umkm",
          },
          {
            title: "Penerima Bantuan Pekerja",
            to: "/admin-kota/data-bantuan-pekerja",
          },
          {
            title: "Penerima Bantuan Ketahanan Pangan",
            to: "/admin-kota/data-bantuan-pangan",
          },
          {
            title: "Penerima Bantuan Lainnya",
            to: "/admin-kota/data-bantuan-lain",
          },
        ],
      },
    ],
    itemsdualas: [
      {
        title: "Kesehatan",
        action: "mdi-infinity",
        items: [
          {
            title: "Peserta Jaminan Kesehatan Nasional",
            to: "/admin-kota/data-sehat-semua",
          },
          { title: "Peserta KIS", to: "/admin-kota/data-sehat-kis" },
          {
            title: "Peserta BPJS Kesehatan Mandiri",
            to: "/admin-kota/data-sehat-bpjsm",
          },
          {
            title: "Peserta BPJS Ketenagakerjaan",
            to: "/admin-kota/data-sehat-bpjsk",
          },
          {
            title: "Peserta Asuransi Lainnya",
            to: "/admin-kota/data-sehat-lain",
          },
          {
            title: "Tidak Memiliki Jaminan Kesehatan Nasional",
            to: "/admin-kota/data-sehat-tidak",
          },
          {
            title: "Stunting",
            to: "/admin-kota/data-sehat-stunting",
          },
          //
          {
            title: "Disabilitas",
            to: "/admin-kota/data-sehat-disabilitas",
          },
          {
            title: "Tunanetra (buta)",
            to: "/admin-kota/data-sehat-buta",
          },
          {
            title: "Tunarungu (tuli)",
            to: "/admin-kota/data-sehat-tuli",
          },
          {
            title: "Tunawicara (bisu)",
            to: "/admin-kota/data-sehat-bisu",
          },
          {
            title: "Tunadaksa (cacat tubuh)",
            to: "/admin-kota/data-sehat-cacat",
          },
          //

          {
            title: "Tunagrahita (cacat mental, keterbelakangan mental)",
            to: "/admin-kota/data-sehat-mental",
          },
          {
            title: "Tunalaras (eks: sakit jiwa, gangguan mengendalika)",
            to: "/admin-kota/data-sehat-jiwa",
          },
          {
            title:
              "Cacat eks - sakit kusta: pernah sakit kusta dan dinyatakan sembuh oleh dokter",
            to: "/admin-kota/data-sehat-kusta",
          },
          {
            title:
              "Cacat ganda (cacat fisik - mental): cacat fisik dan cacat mental)",
            to: "/admin-kota/data-sehat-ganda",
          },
          {
            title: "Dipasung",
            to: "/admin-kota/data-sehat-pasung",
          },
          //
          {
            title: "Sakit Muntaber/diare",
            to: "/admin-kota/data-sehat-muntaber",
          },
          {
            title: "Sakit Demam berdarah",
            to: "/admin-kota/data-sehat-dbd",
          },
          {
            title: "Sakit Campak",
            to: "/admin-kota/data-sehat-campak",
          },
          {
            title: "Sakit Malaria",
            to: "/admin-kota/data-sehat-malaria",
          },
          //
          {
            title: "Sakit Flu burung/SARS",
            to: "/admin-kota/data-sehat-flu",
          },
          {
            title: "Sakit Covid-19",
            to: "/admin-kota/data-sehat-covid",
          },
          {
            title: "Sakit Hepatitis B",
            to: "/admin-kota/data-sehat-hepab",
          },
          {
            title: "Sakit Hepatitis E",
            to: "/admin-kota/data-sehat-hepae",
          },
          //
          {
            title: "Sakit Difteri",
            to: "/admin-kota/data-sehat-difetri",
          },
          {
            title: "Sakit Chikungunya",
            to: "/admin-kota/data-sehat-ciku",
          },
          {
            title: "Sakit Leptospirosis",
            to: "/admin-kota/data-sehat-lepto",
          },
          {
            title: "Sakit Kolera",
            to: "/admin-kota/data-sehat-kolera",
          },
          //
          {
            title: "Sakit Gizi buruk (marasmus dan kwasiorkor)",
            to: "/admin-kota/data-sehat-gizi",
          },
          {
            title: "Sakit Jantung",
            to: "/admin-kota/data-sehat-jantung",
          },
          {
            title: "Sakit TBC paru-paru",
            to: "/admin-kota/data-sehat-tbc",
          },
          {
            title: "Sakit Kanker",
            to: "/admin-kota/data-sehat-kanker",
          },
          //
          {
            title: "Sakit Diabetes/kencing manis/ gula",
            to: "/admin-kota/data-sehat-diabetes",
          },
          {
            title: "Sakit Lumpuh",
            to: "/admin-kota/data-sehat-lumpuh",
          },
          {
            title: "Sakit Stroke",
            to: "/admin-kota/data-sehat-stroke",
          },
          {
            title: "Sakit Lainnya",
            to: "/admin-kota/data-sehat-sakitlain",
          },
        ],
      },
    ],
    itemstigabelas: [
      {
        title: "Meninggal",
        action: "mdi-infinity",
        items: [
          {
            title: "Data Yang Meninggal tahun berjalan Pilihan Tahun",
            to: "/admin-kota/data-penduduk-kematian",
          },
        ],
      },
    ],
    itemslimabelas: [
      {
        title: "Penduduk Keluar,Masuk,Pemecahan",
        action: "mdi-infinity",
        items: [
          {
            title: "KK Masuk Baru",
            to: "/admin-kota/data-penduduk-kkmasuk",
          },
          {
            title: "KK Pemecahan",
            to: "/admin-kota/data-penduduk-kkpecah",
          },
          {
            title: "Penduduk Masuk",
            to: "/admin-kota/data-penduduk-kkpendmasuk",
          },
        ],
      },
    ],
  }),

  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("/auth/login");
    }
    this.email = this.$store.getters.getUser.email;
    this.nama = this.$store.getters.getUser.name;
    this.role = this.$store.getters.getUser.role;
    // console.log(this.$store.getters.getUser.name);
    // this.secretMessage = await AuthService.getSecretContent();
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>
