import { render, staticRenderFns } from "./PublikPerikanan.vue?vue&type=template&id=4fa784de&scoped=true&"
import script from "./PublikPerikanan.vue?vue&type=script&lang=js&"
export * from "./PublikPerikanan.vue?vue&type=script&lang=js&"
import style0 from "./PublikPerikanan.vue?vue&type=style&index=0&id=4fa784de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa784de",
  null
  
)

export default component.exports