<template>
  <div class="d-flex justify-center">
    <v-col coll="12" md="6" sm="12">
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-col>
  </div>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  props: ["databaru"],
  components: { Pie },

  data() {
    return {
      chartData: "",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
      kordinatvalue: {
        latitude: "",
        longitude: "",
      },
    };
  },

  mounted() {
    this.updateCoordinates();
  },
  methods: {
    updateCoordinates() {
      this.chartData = {
        labels: this.databaru.map(
          (item) => item.nama + " : " + item.total + " " + "%"
        ),
        datasets: [
          {
            borderColor: "rgba(200, 200, 200, 0.75)",
            label: "Total Data",

            backgroundColor: [
              "#41B883",
              "#b52d07",
              "#b57007",
              "#127347",
              "#f589a8",
              "#174ceb",
              "#91a8ed",
              "#c591ed",
              "#eb0cdf",
              "#6b284c",
              "#46408c",
              "#46408c",
              "#ff3598",
              "#1a89dd",
              "#721b23",
              "#5f239c",
              "#0b5bb3",
              "#875a65",
              "#875a65",
              "#4656d4",
              "#85ecbf",
            ],
            data: this.databaru.map((item) => item.total),
          },
        ],
      };
    },
  },
};
</script>
