<template>
  <v-container class="py-5 px-6" fluid>
    <v-toolbar dense elevation="0">
      <v-toolbar-title
        ><h5>Bangunan - Data Rumah Tinggal Isi</h5></v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>
    <br />
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <h4 class="mb-6">
          Total Data :
          <span class="teal--text">{{ this.totalItems }}</span>
        </h4>
        <!-- <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
            @input="searchData"
          ></v-text-field>
        </v-col> -->
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader v-if="firstLoad" type="table"></v-skeleton-loader>

      <!-- <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
      </v-data-table> -->
      <v-data-table
        v-if="hasildatas.length > 0"
        :search="search"
        :headers="headers"
        :items="hasildatas"
        :loading="loading"
        hide-default-footer
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        @update:pagination="loadData"
      >
      </v-data-table>

      <v-divider></v-divider>
      <br />
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-pagination
              color="teal"
              v-if="totalItems > 0"
              v-model="pagination.page"
              :length="Math.ceil(totalItems / pagination.itemsPerPage)"
              @input="loadData"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>
      <br />
    </v-card>

    <br />
  </v-container>
</template>
<script>
import AuthService from "@/services/ApiWalikota.js";

export default {
  name: "RtiKota",
  data: () => ({
    headers: [
      { text: "No Bangunan", value: "no_bangunan" },
      { text: "Nama Pemilik", value: "nama_pemilik" },
      { text: "Kecamatan", value: "kecamatan" },
      { text: "Kelurahan", value: "kelurahan" },
      { text: "Dusun", value: "dusun" },
    ],
    hasildatas: [],
    loading: false,
    pagination: {
      page: 1,
      itemsPerPage: 100,
    },
    totalItems: 0,
    search: "",
    firstLoad: true,
  }),
  mounted() {
    this.loadData();
  },
  created: function () {
    // this.dataBangunanRTI();
  },
  methods: {
    searchData() {
      this.pagination.page = 1;
      this.loadData();
    },

    loadData() {
      this.loading = true;
      const start = (this.pagination.page - 1) * this.pagination.itemsPerPage;
      const end = start + this.pagination.itemsPerPage;

      AuthService.dataBangunanRTI(this.pagination.page).then((response) => {
        this.hasildatas = response.data.data.data;
        this.totalItems = response.data.data.total;

        this.firstLoad = false;
        this.loading = false;
      });
    },
    // dataBangunanRTI: function () {
    //   AuthService.dataBangunanRTI(1).then(
    //     function (response) {
    //       this.hasildatas = response.data.data.data;
    //       console.log(this.hasildatas);
    //     }.bind(this)
    //   );
    // },
  },
};
</script>
